import { useMutation } from '@apollo/client';
import { Button, FilledInput, FormControl, Grid, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { IconChevronDown, IconPin } from '@tabler/icons';
import { MUTATION_SET_MY_DASHBOARD_PREFERENCE } from 'graphql/mutations/dashboard';
import { QUERY_GET_MY_DASHBOARD_PREFERENCE } from 'graphql/queries/dashboards';
import { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { setDashboardList, setSelectedDashboard } from 'store/slices/dashboard';
import { Dashboard, GetMyDashboardPreference, MyDashboardPreferenceVariables, SetMyDashboardPreference } from 'views/dashboard/types';

const DashboardTitle = () => {
    const dispatch = useDispatch();
    const dashboardList = useSelector((state) => state.dashboard.dashboardList);
    const selectedDashboard = useSelector((state) => state.dashboard.selectedDashboard);

    const [setMyDashboard] = useMutation<SetMyDashboardPreference, MyDashboardPreferenceVariables>(MUTATION_SET_MY_DASHBOARD_PREFERENCE, {
        update(cache, { data }) {
            const newDefault = data?.setMyDashboardPreference;
            const list = cache.readQuery<GetMyDashboardPreference>({ query: QUERY_GET_MY_DASHBOARD_PREFERENCE });
            if (!list || !newDefault) return;

            cache.writeQuery<GetMyDashboardPreference>({
                query: QUERY_GET_MY_DASHBOARD_PREFERENCE,
                data: { getMyDashboardPreference: newDefault }
            });

            const updatedList = dashboardList.map((dashboard) =>
                +dashboard.id === +newDefault.dashboard.id ? { ...dashboard, selected: true } : { ...dashboard, selected: false }
            );

            dispatch(setSelectedDashboard(newDefault.dashboard));
            dispatch(setDashboardList(updatedList));
        }
    });

    const DashboardListObj = dashboardList.reduce<Record<string, Dashboard>>((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
    }, {});

    const onChangeDashboard = (event: SelectChangeEvent) => {
        const { value } = event.target;
        const selected = dashboardList.find((dashboard) => dashboard.id === value);
        dispatch(setSelectedDashboard(selected));
    };

    const handleChangeDefaultDashboard = (id: string) => async (e: MouseEvent) => {
        e.stopPropagation();
        try {
            await setMyDashboard({ variables: { data: { dashboardId: +id } } });
        } catch (error) {
            console.log('error changing default dashboard', error);
        }
    };

    return (
        <Grid container alignItems="center" spacing={2} style={{ paddingTop: 16 }}>
            <Grid item xs="auto" sx={{ pt: '0 !important' }}>
                <Typography variant="h3" color="secondary" fontFamily="Poppins, sans-serif">
                    <FormattedMessage id="dashboard" />
                </Typography>
            </Grid>
            {!!dashboardList.length && (
                <Grid xs="auto">
                    <FormControl sx={{ width: 280, ml: 2 }}>
                        <Select
                            value={selectedDashboard?.id || ''}
                            onChange={onChangeDashboard}
                            input={<FilledInput id="dashboard-selection-input" disableUnderline hiddenLabel size="small" />}
                            sx={{
                                borderRadius: '100px',
                                '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiFilledInput-input': {
                                    color: '#54595E !important'
                                }
                            }}
                            renderValue={(selected) => DashboardListObj[selected].name}
                            IconComponent={(props) => <IconChevronDown style={{ margin: '-5px 0px', color: '#37326E' }} {...props} />}
                            MenuProps={{ sx: { '& ul': { p: '0 !important' } }, PaperProps: { sx: { borderRadius: '10px' } } }}
                        >
                            {dashboardList.map((option) => (
                                <MenuItem key={option.id} value={option.id} sx={{ bgcolor: option.selected ? '#EBEEFE' : '#F5F6F7' }}>
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <Grid item xs={9}>
                                            <ListItemText
                                                primary={option.name}
                                                sx={{
                                                    '& span': {
                                                        color: '#54595E !important',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Button
                                                sx={(theme) => ({
                                                    borderRadius: '8px',
                                                    minWidth: '30px',
                                                    height: '30px',
                                                    bgcolor: option.selected ? theme.palette.primary.main : '#FFFFFF',
                                                    boxShadow: 'none',
                                                    '&:hover svg': { stroke: '#FFFFFF !important' }
                                                })}
                                                onClick={option.selected ? undefined : handleChangeDefaultDashboard(option.id)}
                                                variant="contained"
                                                size="small"
                                            >
                                                <IconPin size={16} color={option.selected ? '#FFFFFF' : '#37326E'} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
};

export default DashboardTitle;
