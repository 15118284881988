import { useState } from 'react';
import { Avatar, Grid } from '@mui/material';
import { useCommentUsers } from '../hooks';
import { CommentField } from './CommentField';

export type NewCommentSectionProps = {
    onSendComment: (message: any) => void;
};

export const NewCommentSection = ({ onSendComment }: NewCommentSectionProps) => {
    const { currentUser } = useCommentUsers();
    const [isFocused, setIsFocused] = useState(true);

    return (
        <Grid container alignItems="flex-start" justifyContent="flex-end" sx={{ width: '100%', py: '8px' }}>
            <Grid item xs={1}>
                <Avatar
                    sx={(theme) => ({
                        width: 40,
                        height: 40,
                        background: 'white',
                        border: `solid 1px ${theme.palette.secondary.main}`,
                        color: theme.palette.secondary.main
                    })}
                >
                    {currentUser?.name[0] || 'E'}
                </Avatar>
            </Grid>
            <Grid item xs={11}>
                <CommentField
                    isFocused={isFocused}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    onSendComment={onSendComment}
                />
            </Grid>
        </Grid>
    );
};
