/**
 *  Returns a Date Object with the same date of the string provided
 *
 * @param dateStr {String} date string get by the record
 */
export const getDateWithoutTZ = (dateStr: string) => {
    const localizedDate = new Date(dateStr);
    const offset = localizedDate.getTimezoneOffset() > 0 ? localizedDate.getTimezoneOffset() : localizedDate.getTimezoneOffset() * -1;
    const dateOnly = new Date(localizedDate.valueOf() + offset * 60 * 1000);
    return dateOnly;
};
