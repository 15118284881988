import { gql } from '@apollo/client';

export const QUERY_GET_LISTS = gql`
    query GetLists {
        getLists {
            createdAt
            updatedAt
            id
            enabled
            listName
            description
            dataType
            origin
        }
    }
`;

export const QUERY_GET_LIST_VALUES = gql`
    query FindListValues($data: FindListsValuesInput!) {
        findListValues(data: $data) {
            createdAt
            enabled
            id
            order
            userValue {
                id
                lastName
                firstName
                name
            }
            listId {
                id
            }
            updatedAt
            value
        }
    }
`;
