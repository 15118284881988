import { GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { IRecordField, IRecordFieldsList } from '../types';
import { format } from 'date-fns';
import { getTimeValidator } from './getDateByHour';
import { getDateWithoutTZ } from './dateHelpers';

export const valueFormatterList: Record<keyof IRecordFieldsList, (param: GridValueFormatterParams) => string> = {
    date: (params) => {
        if (!params.value || !Date.parse(params.value)) return '';
        return format(getDateWithoutTZ(params.value), 'MM/dd/yyyy');
    },
    datetime: (params) => (params.value && Date.parse(params.value) ? format(new Date(params.value), 'MM/dd/yyyy hh:mm a') : ''),
    time: (params) => getTimeValidator(params.value)
};

export const getValueFormatter = (key: string, dataType: IRecordField['dataType']) => {
    if (dataType === 'date') return valueFormatterList.date;
    if (dataType === 'datetime') return valueFormatterList.datetime;
    if (dataType === 'time') return valueFormatterList.time;
    if (valueFormatterList[key]) return valueFormatterList[key];

    return undefined;
};
