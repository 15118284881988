// material-ui
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Link } from '@mui/material';

// project imports
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
// import LocalizationSection from './LocalizationSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2, IconQuestionCircle, IconSettings } from '@tabler/icons';
import { DASHBOARD_PATH } from 'config';

export const PoetHeader = () => {
    const theme = useTheme();
    const { tenantName } = useParams<{ tenantName: string }>();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <Link underline="none" component={RouterLink} to={`/${DASHBOARD_PATH(tenantName || '')}`} style={{ display: 'flex' }}>
                        <img src="/poet-logo.png" alt="Poet Logo Light" style={{ margin: 'auto', width: '60%' }} />
                    </Link>
                </Box>
                <Avatar
                    data-testid="drawerBtn"
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background: 'transparent',
                        color: '#ffffff',
                        '&:hover': {
                            background: 'transparent',
                            color: '#ffffff'
                        },
                        marginTop: { sm: 1.2 }
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.8} size="1.8rem" />
                </Avatar>
            </Box>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            <IconQuestionCircle stroke={1.5} size={40} color="#ffffff" style={{ cursor: 'pointer' }} />

            <ProfileSection />

            <IconSettings
                color="#ffffff"
                fill="#ffffff"
                stroke={1}
                size={40}
                className="poet-settings-icon"
                style={{ cursor: 'pointer' }}
            />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};
