import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid, Paper, TextField } from '@mui/material';
import { EditorState, convertToRaw } from 'draft-js';
import { EditorSection } from './Editor';

export type CommentFieldProps = {
    isFocused: boolean;
    onBlur: () => void;
    onFocus: () => void;
    onSendComment: (message: any) => void;
};

export const CommentField = ({ isFocused, onBlur, onFocus, onSendComment }: CommentFieldProps) => {
    const intl = useIntl();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    const handleSaveComment = () => {
        const rawDraftContentState = convertToRaw(editorState.getCurrentContent());
        console.log('rawDraftContentState', rawDraftContentState);
        const message = JSON.stringify(rawDraftContentState);
        onSendComment(message);
    };

    if (!isFocused)
        return (
            <TextField
                variant="filled"
                onClick={onFocus}
                size="small"
                InputProps={{ disableUnderline: true, sx: { borderRadius: '10px', paddingLeft: 2 } }}
                placeholder={intl.formatMessage({ id: 'commentPanel.commentField.initial.placeholder' })}
                hiddenLabel
                fullWidth
            />
        );

    return (
        <>
            <Paper sx={{ bgcolor: '#F5F6F7', borderRadius: '8px' }}>
                <EditorSection editorState={editorState} setEditorState={setEditorState} />
            </Paper>
            <Grid container justifyContent="flex-end" sx={{ mt: '10px' }}>
                <Grid item xs="auto">
                    <Button
                        variant="outlined"
                        onClick={onBlur}
                        sx={{ width: '80px', height: '40px', borderRadius: '8px' }}
                        color="secondary"
                        fullWidth
                    >
                        <FormattedMessage id="cancel" />
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        onClick={handleSaveComment}
                        variant="contained"
                        sx={{ width: '80px', height: '40px', borderRadius: '8px', ml: '12px' }}
                        color="secondary"
                        fullWidth
                    >
                        <FormattedMessage id="save" />
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
