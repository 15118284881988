import * as yup from 'yup';
import { ListForm } from './components';
import { CreateListValuesVariables, ListValue, UpdateListValuesVariables } from './types';

export const generateUserValuesData = (ids: number[], existingData: ListValue[], listId: number) => {
    const dataArr:
        | (
              | { isNew: true; data: CreateListValuesVariables['data'] }
              | {
                    isNew: false;
                    data: UpdateListValuesVariables['data'];
                }
          )[] = [];

    // when the user id is not in the array it means we have to disabled
    const deletedUsersValue = existingData.filter((el) => !ids.includes(Number(el.userValue.id)));

    const existingDataNumered = existingData.reduce<Record<number, ListValue>>(
        (acc, el) => ({ ...acc, [Number(el.userValue.id)]: el }),
        {}
    );

    for (const [index, id] of ids.entries()) {
        const existingValue = existingDataNumered[id];
        if (existingValue)
            dataArr.push({
                isNew: false,
                data: {
                    id: Number(existingValue.id),
                    userValue: id,
                    enabled: true,
                    order: index + 1,
                    listId
                }
            });
        else
            dataArr.push({
                isNew: true,
                data: {
                    enabled: true,
                    userValue: id,
                    value: String(id),
                    order: index + 1,
                    listId
                } as CreateListValuesVariables['data']
            });
    }

    for (const value of deletedUsersValue) {
        dataArr.push({
            isNew: false,
            data: {
                id: Number(value.id),
                userValue: Number(value.userValue.id),
                enabled: false,
                listId
            }
        });
    }

    return dataArr;
};

export const formSchema = yup
    .object({
        name: yup.string().trim().min(2, 'Name must be at least 2 characters').required(),
        description: yup.string().trim().min(2, 'Description must be at least 2 characters').required(),
        backofficeManaged: yup.boolean().typeError('backoffice managed is required').required(),
        listType: yup.string().trim().min(2, 'List Type is required').required()
    } as Record<keyof ListForm, any>)
    .required();

export const sortByCreatedAt =
    <T>(order: 'ASC' | 'DESC' = 'DESC') =>
    (a: T & { createdAt: string }, b: T & { createdAt: string }) => {
        const dateA = new Date(a.createdAt).valueOf();
        const dateB = new Date(b.createdAt).valueOf();

        return order === 'DESC' ? dateB - dateA : dateA - dateB;
    };

/**
 * Returns an object with list ids as keys and an array of values as value
 *
 * @param values {ListValue & ListId} array of values from FindListValues
 */
export const orderListValuesById = (values: ListValue[]) => {
    const obj: Record<number, ListValue[]> = {};

    for (const value of values) {
        const idx = Number(value.listId.id);
        if (obj[idx]) obj[idx].push(value);
        else obj[idx] = [value];
    }

    return obj;
};
