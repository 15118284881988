import { IRecordFieldsList } from 'ui-component/records/types';

/**
 * Return an object with the base fields and additional fields splitted
 * and validated with given not allowed function
 *
 * @param headers {IRecordFieldList}
 * @param headers {IRecordFieldList}
 */
export const splitHeaders = (headers?: IRecordFieldsList, filterNotAllowedFn?: (key: string) => boolean) => {
    const baseFieldHeaders: Partial<IRecordFieldsList> = {};
    const additionalFieldHeaders: Partial<IRecordFieldsList> = {};
    if (headers)
        for (const fieldName in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, fieldName)) {
                const element = headers[fieldName];
                if (!filterNotAllowedFn || filterNotAllowedFn(fieldName))
                    if (Number(element.id) === 0) baseFieldHeaders[fieldName] = headers[fieldName];
                    else additionalFieldHeaders[fieldName] = headers[fieldName];
            }
        }

    return { baseFieldHeaders, additionalFieldHeaders };
};

/**
 * Returns and object with all the changes of the record
 *
 * @param oldData {Object} actual data of the record
 * @param newData {Object} new data of the record
 */
export const generateClearedRecordData = (oldData: Record<string, any>, newData: Record<string, number | boolean | string>) => {
    const clearedData: Record<string, any> = {};

    for (const fieldName in newData) {
        if (Object.prototype.hasOwnProperty.call(newData, fieldName)) {
            const oldValue = oldData[fieldName];
            const newValue = newData[fieldName];
            const newValueExist = verifyNewValue(newValue);
            if ((!oldValue && newValueExist) || (oldValue && String(oldValue) !== String(newValue))) {
                clearedData[fieldName] = newValue;
            }
        }
    }

    return clearedData;
};

/**
 * Check if the new value is valid, returns true
 * when exist and have length in string cases
 *
 * @param newVal
 */
const verifyNewValue = (newVal: any) => {
    // TODO: write some tests for number case here
    // TODO: remove currency
    if (['object', 'boolean', '', 'currency'].includes(typeof newVal)) return true;
    if (typeof newVal === 'number') return newVal > 0;
    if (typeof newVal === 'string') return !!newVal.length;
    return false;
};

/**
 * Returns the ids of list that exists in headers
 *
 * @param headers {IRecordFieldsList}
 */
export const getListIdsOfHeaders = (headers: Partial<IRecordFieldsList>) => {
    const ids: number[] = [];

    for (const key in headers) {
        if (Object.prototype.hasOwnProperty.call(headers, key)) {
            const element = headers[key];
            if (element?.dataType === 'dropdown' && element.listType?.id) ids.push(Number(element.listType.id));
        }
    }

    return ids;
};
