import {
    Autocomplete,
    Avatar,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    ListItemText,
    MenuItem,
    SelectProps,
    TextField,
    TextFieldProps,
    Typography,
    styled
} from '@mui/material';
import React, { useState } from 'react';
import { Close as CloseIcon, Search } from '@mui/icons-material';
import { IGetUsers, IUser } from 'views/backoffice/users/types';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import { useQuery } from '@apollo/client';
import AlertMessage from 'ui-component/extended/AlertMessage';

interface ShareDialogProps {
    open: boolean;
    onClose: () => void;
}

export const StyledSelect = styled(Autocomplete)<SelectProps<string[]>>(() => ({
    mb: 2,
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C4C4C4 !important'
    },
    '& .MuiInputBase-input': {
        backgroundColor: 'white'
    }
}));

export const StyledFormTextField = styled(TextField)<TextFieldProps>(() => ({
    '& label.Mui-focused': {
        color: '#666666',
        marginBottom: '.5em ',
        '& .required-text': {
            color: '#C10230'
        }
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#C4C4C4'
        }
    },
    '& .MuiInputBase-input': {
        backgroundColor: 'white'
    }
}));

export const ShareDialog = ({ open, onClose }: ShareDialogProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [userToShareList, setUserToShareList] = useState<string[]>([]);
    const [message, setMessage] = useState('');
    const { data, loading: loadingUsers } = useQuery<IGetUsers>(QUERY_GET_USERS);

    const users = data?.getSaasUsers || [];

    const handleDeleteUserFromlist = (id: string) => () => {
        setUserToShareList((s) => s.filter((el) => el !== id));
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        // Fake loading
        await new Promise((res) => {
            setTimeout(() => {
                res(true);
            }, 2000);
        });
        setIsLoading(false);
        setShowSuccess(true);

        // Show success and close Dialog
        await new Promise((res) => {
            setTimeout(() => {
                onClose();
                setMessage('');
                setShowSuccess(false);
                res(true);
            }, 2000);
        });
    };

    return (
        <>
            {!showSuccess ? (
                <Dialog open={open} onClose={onClose} maxWidth="sm" sx={{ '& .MuiPaper-root': { paddingX: 0, pt: 0 } }} fullWidth>
                    {isLoading && <LinearProgress color="secondary" />}
                    <DialogTitle component="div" sx={{ paddingX: 3 }}>
                        <Grid container alignItems="center">
                            <Typography variant="h3" sx={{ flexGrow: 1, fontWeight: 'normal', color: 'black' }}>
                                Share File
                            </Typography>
                            <IconButton onClick={onClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </DialogTitle>
                    <DialogContent sx={{ marginY: 2 }}>
                        <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 'normal', color: 'black', mb: 2 }} component="div">
                            Share with
                        </Typography>

                        <StyledSelect
                            loading={loadingUsers}
                            value={[]}
                            onChange={(e, value) => {
                                // @ts-ignore
                                const idsArr = value.map((el) => el.id) as string[];
                                setUserToShareList((s) => [...s, ...idsArr]);
                            }}
                            renderInput={(params) => <TextField {...params} focused variant="outlined" label="People" sx={{ mb: 2 }} />}
                            renderOption={(props, option) => {
                                const { id, name } = option as IUser;
                                return (
                                    <MenuItem {...props} key={id} value={id}>
                                        <Checkbox checked={!!userToShareList.find((el) => Number(el) === Number(id))} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                );
                            }}
                            popupIcon={<Search fontSize="small" />}
                            options={users}
                            getOptionLabel={(option: any) => option.name}
                            disableCloseOnSelect
                            multiple
                        />

                        {userToShareList.map((el) => {
                            const label = users.find((user) => Number(user.id) === Number(el))?.name || '';
                            return (
                                <Chip
                                    avatar={<Avatar>{label[0]}</Avatar>}
                                    label={label}
                                    onDelete={handleDeleteUserFromlist(el)}
                                    sx={{ mb: 2 }}
                                />
                            );
                        })}

                        <Typography
                            variant="body1"
                            sx={{ fontSize: '16px', fontWeight: 'normal', color: 'black', mt: 4, mb: 2 }}
                            component="div"
                        >
                            Add a message
                        </Typography>

                        <StyledFormTextField
                            variant="outlined"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            rows={4}
                            focused
                            multiline
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'end', paddingX: 3 }}>
                        <Button variant="contained" color="secondary" onClick={handleSubmit}>
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <AlertMessage severity="success" title="Success!" open={showSuccess} onClose={() => {}}>
                    Document shared.
                </AlertMessage>
            )}
        </>
    );
};

export default ShareDialog;
