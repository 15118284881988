import { IAditionalFields, IRecordFieldsList, IRecordHeaders, IRoleAccess } from 'ui-component/records/types';
import { filterNotAllowedFields, getFieldName } from 'ui-component/records/utils';
import { getYesterdayDate } from 'views/CreateRecord/utils';
import * as yup from 'yup';
import { getDateWithoutTZ } from 'ui-component/records/utils/dateHelpers';
import { format } from 'date-fns';
import { DROPDOWN_COLUMNS } from 'ui-component/records/utils/columnHelpers';

export const schema = yup
    .object({
        problemCodeId: yup.number().typeError('Problem code is required').required('Problem code is a required field'),
        recordNumber: yup.number().typeError('Record number have to be a valid number').required('Record number is a required field'),
        statusId: yup.number().typeError('Record status is required').required('Status is a required field'),
        poNumber: yup.string().typeError('PO Number have to be a valid number').required('PO Number is a required field'),
        assignedTo: yup.number().typeError('Assigned to is required').required('Assigned to is required'),
        approvedBy: yup.number().typeError('Approved by is required').required('Approved by is required'),
        recordDate: yup.date().max(getYesterdayDate(), 'Record Date must be earlier than today'),
        dueDate: yup.date().min(new Date(), 'Record Date must be before today')
    })
    .required();

export const getAditionalFieldsObj = (requiredFields: Partial<IRecordFieldsList>, aditionalFields: IAditionalFields[]) => {
    const aditionalFieldsObj: any = {};
    for (const key in requiredFields) {
        if (Object.prototype.hasOwnProperty.call(requiredFields, key)) {
            const dataType = requiredFields[key]?.dataType.toLowerCase();
            let value = null;

            const field = aditionalFields.find((el) => {
                let [, , fieldId] = el.tag.split(';');
                fieldId = fieldId || el.tag.split(':')[1].split(';')[1];
                return Number(fieldId) === Number(requiredFields[key]?.id);
            });

            if (field) {
                if (dataType === 'date') {
                    value = Date.parse(field.value) ? format(getDateWithoutTZ(field.value as string), 'MM/dd/yyyy') : '';
                } else if (dataType === 'datetime') {
                    value = Date.parse(field.value) ? format(new Date(field.value as string), 'MM/dd/yyyy hh:mm a') : '';
                } else if (dataType === 'object') {
                    const displayValue: string[] = [];
                    if (field.objectValue) {
                        [...field.objectValue.objectValues]
                            ?.sort((a, b) => {
                                const orderA = a.objectProperty.order; // ignore upper and lowercase
                                const orderB = b.objectProperty.order; // ignore upper and lowercase
                                if (orderA < orderB) {
                                    return -1;
                                }
                                if (orderA > orderB) {
                                    return 1;
                                }
                                return 0;
                            })
                            .forEach((val) => {
                                if (val.objectProperty.isDisplayable) {
                                    displayValue.push(val.value);
                                }
                            });
                    }
                    value = displayValue.join(', ');
                } else {
                    value = field.value as string;
                }
            }

            aditionalFieldsObj[key] = value || '';
        }
    }

    // aditionalFields.forEach((field) => {
    //     const [, , fieldId] = field.tag.split(';');
    //     const secondOptionFieldId = !fieldId && field.split(':')[1].split(';')[1];
    //     const key = getFieldName(requiredFields, fieldId || secondOptionFieldId);
    //     if (key !== 'not found') {
    //         const value = field.value;
    //         const dataType = requiredFields[key].dataType.toLowerCase();
    //         if (dataType === 'dropdown') {
    //             aditionalFieldsObj[key] = (value as Record<string, string>)?.name || 'No Data';
    //         } else if (dataType === 'date') {
    //             aditionalFieldsObj[key] = Date.parse(value as string) ? format(new Date(value as string), 'yyyy/MM/dd') : 'No Data';
    //         } else {
    //             aditionalFieldsObj[key] = (value as string) || 'No Data';
    //         }
    //     }
    // });
    return aditionalFieldsObj;
};

export const getAditionalFieldsValues = (requiredFields: Partial<IRecordFieldsList>, aditionalFields: IAditionalFields[]) => {
    const obj: any = {};

    aditionalFields.forEach((field) => {
        let [, , fieldId] = field.tag.split(';');
        fieldId = fieldId || field.tag.split(':')[1].split(';')[1];
        const key = getFieldName(requiredFields, fieldId || fieldId);
        if (key !== 'not found') {
            const value = field.value;
            const dataType = requiredFields[key]?.dataType.toLowerCase();
            if (dataType === 'dropdown') {
                obj[key] = (value as string) || '';
            } else if (dataType === 'date') {
                obj[key] = Date.parse(field.value) ? format(getDateWithoutTZ(field.value as string), 'MM/dd/yyyy') : '';
            } else if (dataType === 'datetime') {
                obj[key] = Date.parse(value as string) ? format(new Date(value as string), 'MM/dd/yyyy hh:mm a') : '';
            } else if (dataType === 'object') {
                const displayValue: string[] = [];
                if (field.objectValue) {
                    [...field.objectValue.objectValues]
                        ?.sort((a, b) => {
                            const orderA = a.objectProperty.order; // ignore upper and lowercase
                            const orderB = b.objectProperty.order; // ignore upper and lowercase
                            if (orderA < orderB) {
                                return -1;
                            }
                            if (orderA > orderB) {
                                return 1;
                            }
                            return 0;
                        })
                        .forEach((val) => {
                            if (val.objectProperty.isDisplayable) {
                                displayValue.push(val.value);
                            }
                        });
                }
                obj[key] = displayValue.join(', ');
            } else {
                obj[key] = (value as string) || '';
            }
        }
    });
    return obj;
};

export const getAditionalFieldsHeaders = (
    aditionalFields?: IRecordFieldsList,
    roleId?: number | null,
    recordType?: string,
    fieldAccessList?: IRoleAccess[]
) => {
    if (!aditionalFields || !fieldAccessList || !roleId) return null;
    const aditionalFieldsList: Partial<IRecordFieldsList> = {};
    const isAllowed = filterNotAllowedFields(fieldAccessList, Number(recordType), roleId);

    for (const key in aditionalFields) {
        if (Object.prototype.hasOwnProperty.call(aditionalFields, key)) {
            const element = aditionalFields[key];
            if (Number(element.id) !== 0 && isAllowed(key)) aditionalFieldsList[key] = element;
        }
    }

    return aditionalFieldsList;
};
export const getBaseFields = (
    fieldList: IRecordFieldsList | null,
    roleId: number | null,
    recordType?: string,
    fieldAccessList?: IRoleAccess[]
) => {
    if (!fieldList || !fieldAccessList) return null;
    const baseFields: Partial<IRecordFieldsList> = {};
    const isAllowed = filterNotAllowedFields(fieldAccessList, Number(recordType), roleId);

    for (const key in fieldList) {
        if (Object.prototype.hasOwnProperty.call(fieldList, key) && !['id', 'tenant', 'enabled'].includes(key)) {
            const element = fieldList[key];
            if (Number(element.id) === 0 && isAllowed(key)) baseFields[key] = element;
        }
    }

    return baseFields;
};

export const getBaseFilterFields = (fieldList: IRecordFieldsList | null, includeFields: string[]) => {
    if (!fieldList) return null;
    const baseFields: Partial<IRecordFieldsList> = {};

    for (const key in fieldList) {
        if (
            Object.prototype.hasOwnProperty.call(fieldList, key) &&
            !['id', 'tenant', 'enabled'].includes(key) &&
            includeFields.includes(key)
        ) {
            const element = fieldList[key];
            if (Number(element.id) === 0) baseFields[key] = element;
        }
    }

    return baseFields;
};

/**
 * Function to generate Object with key and values of the view panel
 *
 * @param recordData {IRecordHeaders} Data of the record
 * @param baseFields {Partial<IRecordFieldsList>} Headers of base fields
 * @param aditionalFields {Partial<IRecordFieldsList>} Header of aditionalFields
 * @param fieldAccessList {IRoleAccess[]} List of access and order
 * @returns
 */
export const getViewFields = (
    recordData: IRecordHeaders | null,
    baseFields: Partial<IRecordFieldsList> | null,
    aditionalFields: Partial<IRecordFieldsList> | null,
    fieldAccessList: IRoleAccess[]
) => {
    if (!baseFields || !recordData || !aditionalFields) return {};
    let data: Record<string, string> = {};
    for (const key in baseFields) {
        if (Object.prototype.hasOwnProperty.call(baseFields, key)) {
            const value = recordData[key as keyof IRecordHeaders];
            const dataType = baseFields[key]?.dataType.toLowerCase();
            if (dataType === 'dropdown' || DROPDOWN_COLUMNS.includes(key as (typeof DROPDOWN_COLUMNS)[number])) {
                data[key] = (value as Record<string, string>)?.name || '';
            } else if (dataType === 'date') {
                data[key] = Date.parse(value as string) ? (value as string) : '';
            } else {
                data[key] = (value as string) || '';
            }
        }
    }

    data = { ...data, ...getAditionalFieldsObj(aditionalFields, recordData.additionalFields) };
    const orderedData = orderFieldsObj(data, fieldAccessList);
    return orderedData;
};

/**
 * Generate the order object of fields
 *
 * @param fields {Record<string, any>} List of normalize fields
 * @param accessList {IRoleAccess[]} List of access and order
 * @returns
 */
export const orderFieldsObj = (fields: Record<string, any>, accessList: IRoleAccess[]) => {
    const entries = Object.entries(fields).sort((a, b) => {
        const orderA = getOrderFromKey(a[0], accessList);
        const orderB = getOrderFromKey(b[0], accessList);

        return orderA - orderB;
    });

    return Object.fromEntries(entries);
};

/**
 * Get the order of the given key
 *
 * @param key {String} key of the recordField
 * @param accessList {IRoleAccess[]} List of access and order
 * @returns Number of the order or zero
 */
const getOrderFromKey = (key: string, accessList: IRoleAccess[]) => {
    const fieldAccess = accessList.find((el) => el.recordFieldName.toLowerCase() === key.toLowerCase());

    if (!fieldAccess) return Infinity;

    return fieldAccess.order > -1 ? fieldAccess.order : Infinity;
};

/**
 * Check if is a valid date string
 *
 * @param dateStr {String} date in string format
 * @returns
 */
export const isDateValid = (dateStr: string) => dateStr.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/);
