import { gql } from '@apollo/client';

export const MUTATION_REGISTER_LINE_ITEM = gql`
    mutation CreateLineItems($data: CreateLineItemsInput!) {
        createLineItems(data: $data) {
            id
            tag
            value
            index
            createdAt
            updateAt
        }
    }
`;
export const MUTATION_UPDATE_LINE_ITEM = gql`
    mutation UpdateLineItems($data: UpdateLineItemsInput!) {
        updateLineItems(data: $data) {
            id
            tag
            value
            index
            createdAt
            updateAt
        }
    }
`;
