import jwtDecode from 'jwt-decode';
import { useQuery } from '@apollo/client';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import { IUserDataToken } from 'utils/types';
import { IGetUsers, IUser } from 'views/backoffice/users/types';

export const useCommentUsers = () => {
    const token = localStorage.getItem('backend_jwt') || '';
    const { data: usersData } = useQuery<IGetUsers>(QUERY_GET_USERS);
    const user: IUserDataToken = jwtDecode(token);

    const userList = usersData?.getSaasUsers.filter(({ id }) => +id !== +user.userId) || [];
    const usersById: Record<number, IUser> = userList.reduce((acc, el) => ({ ...acc, [el.id]: el }), {});
    const currentUser = usersData?.getSaasUsers.find(({ id }) => +id === +user.userId) as IUser;

    return { userList, usersById, currentUser };
};
