import { Button } from '@mui/material';
import { Close, EditTwoTone } from '@mui/icons-material';

export const GridToolbarEdit = ({ onClick, isEditing, show }: { onClick: () => void; isEditing: boolean; show: boolean }) => (
    <>
        {show && (
            <Button
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px 16px'
                })}
                size="medium"
                color="primary"
                startIcon={isEditing ? <Close sx={{ fontSize: '1.1rem' }} /> : <EditTwoTone sx={{ fontSize: '1.1rem' }} />}
                onClick={onClick}
            >
                {isEditing ? 'Close' : ''} Edit
            </Button>
        )}
    </>
);
