// types
import { MenuProps } from 'types/menu';
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState: MenuProps = {
    selectedItem: [''],
    menuItem: [],
    drawerOpen: false,
    actionData: [],
    menuItemsList: []
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        activeMenuItem(state, action) {
            state.menuItem = action.payload;
        },

        saveActionData(state, action) {
            state.actionData = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },
        saveMenuItemList(state, action) {
            state.menuItemsList = action.payload;
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer, activeMenuItem, saveActionData, saveMenuItemList } = menu.actions;
