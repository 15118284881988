import { forwardRef, useEffect, useImperativeHandle, useState, useMemo, useCallback, MutableRefObject } from 'react';
import { Edit, Save, Close } from '@mui/icons-material';
import {
    GridRowModesModel,
    GridRowModes,
    DataGridPro,
    GridColDef,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    DataGridProProps,
    GridSlotsComponent,
    GridRowOrderChangeParams,
    useGridApiRef,
    GridApiPro
} from '@mui/x-data-grid-pro';
import { getOnlyUpdatedRows, orderAlphabetical, randomInteger, reorder } from './utils';
import { GridToolbar, GridToolbarProps } from './components';
import { SxProps } from '@mui/system';

export type GridWithInlineEditProps = {
    apiRef?: MutableRefObject<GridApiPro>;
    GridComponent?: (props: DataGridProProps) => JSX.Element | null;
    loading: boolean;
    initialRows: readonly Record<string, any>[];
    columns: GridColDef[];
    shouldEdit?: boolean;
    onUpdate: (newRow: Record<string, any>) => Promise<Record<string, any> | undefined>;
    onCreate: (newRow: Record<string, any>) => Promise<Record<string, any> | undefined>;
    disabledReordering?: boolean;
    disabledCheckboxSelection?: boolean;
    fieldToFocus?: string;
    density?: 'standard' | 'comfortable' | 'compact';
    onUpdateOrder?: (newOrder: Record<string, any>[]) => Promise<boolean>;
    gridComponents?: Partial<GridSlotsComponent>;
    autosizeColumns?: boolean;
    pinnedActions?: boolean;
    sx?: SxProps;
    showQuickFilter?: boolean;
};

export type GridWithInlineEditRef = {
    handleAddClick: () => void;
};

/**
 * Grid with Inline Edit
 *
 * This component is used to render a grid with inline edit functionality
 * exposing the add new row functionality to the parent component
 *
 */
const GridWithInlineEdit = forwardRef(
    (
        {
            apiRef: inheritApiRef,
            GridComponent = DataGridPro,
            loading,
            initialRows,
            columns,
            onUpdate,
            onCreate,
            disabledReordering,
            disabledCheckboxSelection,
            fieldToFocus,
            onUpdateOrder,
            gridComponents,
            density = 'standard',
            sx,
            shouldEdit = false,
            autosizeColumns = false,
            pinnedActions = false,
            showQuickFilter = false
        }: GridWithInlineEditProps,
        ref
    ) => {
        const apiRef = useGridApiRef();

        const [isReordering, setIsReordering] = useState(false);
        const [rows, setRows] = useState<GridRowModel[]>([...initialRows].sort((a, b) => a.order - b.order));
        const [reorderedRows, setReorderedRows] = useState([...initialRows].sort((a, b) => a.order - b.order));
        const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

        const handleEditClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            },
            [rowModesModel]
        );

        const handleSaveClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
            },
            [rowModesModel]
        );

        const handleCancelClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({
                    ...rowModesModel,
                    [id]: { mode: GridRowModes.View, ignoreModifications: true }
                });

                const editedRow = rows.find((row) => row.id === id);
                if (editedRow?.isNew) {
                    setRows(rows.filter((row) => row.id !== id));
                }
            },
            [rowModesModel, rows]
        );

        const handleAddClick = () => {
            const id = randomInteger();
            const editableFields = Object.keys(columns.filter((column) => column.editable)).reduce(
                (acc, key) => ({ ...acc, [key]: '' }),
                {}
            );
            setRows((oldRows) => [...oldRows, { id, isNew: true, ...editableFields }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus }
            }));
        };

        const processRowUpdate = async (newRow: GridRowModel) => {
            let updatedRow: Record<string, any> | undefined = { ...newRow };
            if (updatedRow.isNew) {
                updatedRow.isNew = false;
                updatedRow = await onCreate(updatedRow);
            } else {
                updatedRow = await onUpdate(updatedRow);
            }

            if (updatedRow) {
                setRows(rows.map((row) => (row.id === newRow.id ? (updatedRow as Record<string, any>) : row)));

                return updatedRow;
            }

            return newRow;
        };

        const processRowReorderingUpdate = async () => {
            const onlyUpdatedRows = getOnlyUpdatedRows(rows, reorderedRows);

            if (onUpdateOrder) {
                const success = await onUpdateOrder(onlyUpdatedRows);
                if (success) {
                    setRows(reorderedRows);
                    setIsReordering(false);
                }
            }
        };

        const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
            event.defaultMuiPrevented = true;
        };

        const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
            event.defaultMuiPrevented = true;
        };

        const handleRowReorderChange = ({ targetIndex, oldIndex }: GridRowOrderChangeParams) => {
            const updatedOrder = reorder<Record<string, any>>(rows, oldIndex, targetIndex);
            setReorderedRows(updatedOrder.map((row, index) => ({ ...row, order: index + 1 })));
        };

        const handleRowAlphabeticalOrder = () => {
            const updatedOrder = orderAlphabetical(rows, fieldToFocus || 'value');
            setReorderedRows(updatedOrder);
            apiRef.current.setRows(updatedOrder);
        };

        const allColumns: GridColDef[] = useMemo(() => {
            let defaultColumns = [...columns];
            if (shouldEdit)
                defaultColumns = [
                    {
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Actions',
                        width: 100,
                        cellClassName: 'actions',
                        renderCell: ({ id }) => {
                            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                            if (isInEditMode) {
                                return (
                                    <>
                                        <GridActionsCellItem icon={<Save />} label="Save" onClick={handleSaveClick(id)} />,
                                        <GridActionsCellItem
                                            icon={<Close />}
                                            label="Cancel"
                                            className="textPrimary"
                                            onClick={handleCancelClick(id)}
                                            color="inherit"
                                        />
                                    </>
                                );
                            }

                            return (
                                <GridActionsCellItem
                                    icon={<Edit />}
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={handleEditClick(id)}
                                    color="inherit"
                                />
                            );
                        }
                    },
                    ...defaultColumns
                ];

            return defaultColumns;
        }, [columns, handleCancelClick, handleEditClick, handleSaveClick, rowModesModel, shouldEdit]);

        useImperativeHandle(ref, () => ({
            handleAddClick
        }));

        // Needed to autosize columns after the edit mode change
        useEffect(() => {
            if (autosizeColumns && apiRef.current && rowModesModel)
                apiRef.current.autosizeColumns({
                    includeHeaders: true,
                    includeOutliers: true
                });
        }, [apiRef, autosizeColumns, rowModesModel]);

        useEffect(() => {
            if (initialRows) {
                setRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));
                setReorderedRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));

                if (autosizeColumns)
                    apiRef.current.autosizeColumns({
                        includeHeaders: true,
                        includeOutliers: true
                    });
            }
        }, [initialRows, apiRef, autosizeColumns]);

        return (
            <GridComponent
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={allColumns}
                editMode="row"
                density={density}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                initialState={{ pinnedColumns: { left: pinnedActions ? ['actions'] : [] } }}
                components={{ ...gridComponents, Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        ToolbarButtons: gridComponents?.Toolbar,
                        onAlphabeticalOrder: handleRowAlphabeticalOrder,
                        rowReorder: !disabledReordering,
                        onSaveReorder: processRowReorderingUpdate,
                        toggleReorder: () => {
                            if (isReordering) apiRef.current.setRows(rows);
                            setIsReordering(!isReordering);
                            setReorderedRows(rows);
                        },
                        isReordering,
                        showQuickFilter
                    } as GridToolbarProps
                }}
                sx={sx}
                onProcessRowUpdateError={(error) => console.log('error', error)}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                checkboxSelection={!disabledCheckboxSelection}
                disableRowSelectionOnClick
                pagination
                rowReordering={!disabledReordering && isReordering}
                onRowOrderChange={handleRowReorderChange}
            />
        );
    }
);

export default GridWithInlineEdit;
