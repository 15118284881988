import {
    GRID_ACTIONS_COLUMN_TYPE,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridColumnVisibilityModel,
    GridFilterItem,
    GridFilterModel,
    GridLogicOperator,
    GridPinnedColumns
} from '@mui/x-data-grid-pro';
import { IFilterModel, IGridPreference, IRecordFieldsList, filterOperatorEnum } from '../types';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

// This have to change in the future when backend modify the preference value
/**
 * Operators of the grid that are arrays
 */
export const ARRAY_OPERATORS = ['isAnyOf'] as const;

/**
 * Return the Grid State for the Data Grid Component
 *
 * @param preference {IGridPreference}
 * @param fields {keyof IRecordFieldsList[]}
 * @param showExpand {Boolean}
 * @returns
 */
export const generateGridState = (
    preference: IGridPreference,
    fields: (keyof IRecordFieldsList)[],
    showExpand = false
): GridInitialStatePro => {
    const { orderedFields, ...states } = preference.gridOptions[0];

    const columnVisibilityModel = fields.reduce<Record<keyof IRecordFieldsList, boolean>>(
        (acc: any, el: keyof IRecordFieldsList) => ({ ...acc, [el]: !states.hiddenFields.includes(el as string) }),
        {}
    ) as GridColumnVisibilityModel;

    const filterModel: GridFilterModel = {
        items: states.filterModel.map((item, key) => ({
            ...item,
            id: key,
            field: item.columnField,
            operator: item.operatorValue?.slice(-1)[0] || 'contains',
            value:
                item.operatorValue && ARRAY_OPERATORS.includes(item.operatorValue?.slice(-1)[0] as (typeof ARRAY_OPERATORS)[number])
                    ? item.value
                    : item.value?.[0] || null
        })),
        logicOperator: getLinkOperator(states.filterModel) as GridLogicOperator
    };

    const orderedFieldsWithouthGridColDefs = orderedFields.filter(
        (field) => ![GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_ACTIONS_COLUMN_TYPE, GRID_DETAIL_PANEL_TOGGLE_FIELD].includes(field)
    );

    const orderedFieldsWithGridDefs = showExpand
        ? [
              GRID_CHECKBOX_SELECTION_COL_DEF.field,
              GRID_ACTIONS_COLUMN_TYPE,
              GRID_DETAIL_PANEL_TOGGLE_FIELD,
              ...new Set(orderedFieldsWithouthGridColDefs)
          ]
        : [GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_ACTIONS_COLUMN_TYPE, ...new Set(orderedFieldsWithouthGridColDefs)];

    // Pin checkbox actions and detail panel columns by default
    const pinnedColumns: GridPinnedColumns = {
        left: ['__check__', 'actions', '__detail_panel_toggle__', ...(states.pinnedColumns?.left || [])],
        right: states.pinnedColumns?.left || []
    };

    const obj: GridInitialStatePro = {
        pinnedColumns: pinnedColumns as GridPinnedColumns,
        columns: {
            dimensions: {},
            columnVisibilityModel,
            orderedFields: orderedFieldsWithGridDefs
        },
        preferencePanel: {
            open: false
        },
        filter: {
            filterModel
        },
        sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }]
        },
        pagination: {
            paginationModel: {
                pageSize: states.pageSize || 25,
                page: 0
            }
        }
    };

    return obj;
};

/**
 * Return the link operator if this exist in operator value array for each item
 * otherwise return AND operator
 *
 * @param filterModel {IFilterModel[]}
 * @returns
 */
export const getLinkOperator = (filterModel?: IFilterModel[] & Partial<GridFilterItem>[]) => {
    if (!filterModel || !filterModel.length || filterModel[0].operatorValue?.length === 1) return filterOperatorEnum.AND.toLowerCase();
    return filterModel[0].operatorValue?.[0].toLowerCase() || filterOperatorEnum.AND.toLowerCase();
};
