/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, List, MenuItem, Skeleton, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'store';
import { IRecordField, IRecordFieldsList, IRecordHeaders, IRoleAccess } from 'ui-component/records/types';
import { RenderInput } from 'ui-component/RecordView/components';
import { IGetRecordTypes } from 'views/CreateRecord/types';
import { QUERY_GET_RECORD_TYPES } from 'graphql/queries/recordTypes';
import { PanelFieldSection, PanelFieldSectionInline } from 'views/CreateRecord/components';
import { useLazyQuery, useQuery } from '@apollo/client';
import { NON_EDITABLE_FIELDS, getEditableField } from 'ui-component/records/utils/editableFields';
import useMyRole from 'hooks/useMyRole';
import { orderFieldsObj } from '../utils';
import { QUERY_FIND_LIST_VALUES } from 'graphql/queries/lists';
import { getDropdownOptionList } from 'ui-component/RecordView/utils';
import { FindListValues, FindListValuesVariables } from 'views/backoffice/CustomLists/types';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import useMyDropdowns from 'hooks/useMyDropdowns';

interface EditPanelProps {
    recordTypeId?: string;
    isCreate?: boolean;
    onChangeRecordType: (recordTypeId: string) => void;
    baseFieldHeaders: Partial<IRecordFieldsList> | null;
    aditionalFieldHeader: Partial<IRecordFieldsList> | null;
    loadingFields: boolean;
    fieldAccessList: IRoleAccess[];
    isObjectPanelOpen: boolean;
    handleOpenViewObjectPanel: (field: IRecordField, fieldName: string, editable: boolean) => void;
    inMemoryObjectProperties: { [key: string]: (ObjectProperty & { value: string })[] };
    recordData: IRecordHeaders | null;
}

export interface EditPanelForm extends CreatePanelForm {
    id: number;
}

export interface CreatePanelForm {
    recordType: number;
    problemCodeId: number;
    recordNumber: string;
    recordDate: string;
    dueDate: string;
    statusId: number;
    poNumber: string;
    assignedTo: number;
    approvedBy: number;
    [key: string]: any;
}

const EditPanel = ({
    isCreate,
    recordTypeId,
    onChangeRecordType,
    aditionalFieldHeader,
    baseFieldHeaders,
    loadingFields,
    fieldAccessList,
    handleOpenViewObjectPanel,
    inMemoryObjectProperties,
    isObjectPanelOpen,
    recordData
}: EditPanelProps) => {
    const { problemCodeList, recordStatusList, usersList, targetTenantList } = useMyDropdowns({ recordTypeId: Number(recordTypeId) });

    const { data: recordTypeData } = useQuery<IGetRecordTypes>(QUERY_GET_RECORD_TYPES);
    const [findListValue, { loading: findListValueLoading }] = useLazyQuery<FindListValues, FindListValuesVariables>(
        QUERY_FIND_LIST_VALUES,
        { fetchPolicy: 'no-cache' }
    );
    const recordTypeList = recordTypeData?.getRecordTypes || [];
    const [customListValues, setCustomListValues] = useState<Record<number, any[]>>({});
    const roleId = useMyRole();
    const { drawerOpen } = useSelector((state) => state.menu);
    // Objects Control

    const {
        control,
        resetField,
        watch,
        formState: { errors },
        setValue
    } = useFormContext<CreatePanelForm | EditPanelForm>();

    const recordTypeSelected = watch('recordType');

    const allFieldsHeaders = orderFieldsObj({ ...baseFieldHeaders, ...aditionalFieldHeader }, fieldAccessList);

    const getListValue = useCallback(
        async (headerFields: IRecordField[]) => {
            for await (const value of headerFields) {
                const listId = value?.listType?.id as number;
                const { data } = await findListValue({ variables: { data: { listId } } });
                setCustomListValues((prev) => ({
                    ...prev,
                    [listId]: data?.findListValues.filter((el) => el.enabled).sort((a, b) => a.order - b.order) || []
                }));
            }
        },
        [findListValue]
    );

    useEffect(() => {
        if (recordTypeSelected) onChangeRecordType(String(recordTypeSelected));
    }, [onChangeRecordType, recordTypeSelected]);

    useEffect(() => {
        if (aditionalFieldHeader && Object.keys(aditionalFieldHeader).length) {
            const listTypesHeaderValues = Object.values(aditionalFieldHeader).filter((value) => value && value.listType);
            getListValue(listTypesHeaderValues as IRecordField[]);
        }
    }, [aditionalFieldHeader, getListValue]);

    if (isObjectPanelOpen) return <></>;

    return (
        <>
            {findListValueLoading ? (
                <List>
                    {[1, 2, 3, 4, 5].map((el) => (
                        <Grid component="li" key={el} container item xs={12} ml={2} mr={1} sx={{ my: '20px' }}>
                            <Grid item xs={5}>
                                <Skeleton height={20} animation="wave" />
                            </Grid>
                            <Grid item xs={8} mt={1}>
                                <Skeleton height={30} animation="wave" />
                            </Grid>
                        </Grid>
                    ))}
                </List>
            ) : (
                <Grid container maxWidth="md" data-testid="edit-form" sx={{ paddingBottom: '20px' }}>
                    <Box id="edit-form">
                        {isCreate && (
                            <>
                                {drawerOpen ? (
                                    <PanelFieldSection isRecordViewer noPadding title="Record Type *">
                                        <Controller
                                            name="recordType"
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={0}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    select
                                                    error={!!errors.recordType}
                                                    helperText={errors.recordType?.message}
                                                    fullWidth
                                                    disabled={!!recordTypeId}
                                                    {...field}
                                                >
                                                    {recordTypeList.map((el) => (
                                                        <MenuItem key={el.id} value={Number(el.id)}>
                                                            <em>{el.name}</em>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </PanelFieldSection>
                                ) : (
                                    <PanelFieldSectionInline noPadding title="Record Type *">
                                        <Controller
                                            name="recordType"
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={0}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    select
                                                    sx={{ textAlign: 'left' }}
                                                    error={!!errors.recordType}
                                                    helperText={errors.recordType?.message}
                                                    fullWidth
                                                    disabled={!!recordTypeId}
                                                    {...field}
                                                >
                                                    {recordTypeList.map((el) => (
                                                        <MenuItem key={el.id} value={Number(el.id)}>
                                                            <em>{el.name}</em>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </PanelFieldSectionInline>
                                )}
                            </>
                        )}

                        {loadingFields &&
                            [1, 2, 3, 4, 5, 6].map((el) => (
                                <Grid key={el} container item xs={12} ml={2} mr={1} sx={{ my: '20px' }}>
                                    <Grid item xs={4}>
                                        <Skeleton height={20} animation="wave" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton height={75} animation="wave" />
                                    </Grid>
                                </Grid>
                            ))}

                        {!loadingFields &&
                            !!Object.keys(allFieldsHeaders || {}).length &&
                            Object.entries(allFieldsHeaders || {})
                                .filter(([key]) => !(NON_EDITABLE_FIELDS as ReadonlyArray<string>).includes(key))
                                .map(([key, value]) => {
                                    const isCustomList = value?.listType;
                                    // const isObject = value.dataType.toLowerCase() === 'object';
                                    const options = isCustomList
                                        ? customListValues[value.listType.id] || []
                                        : getDropdownOptionList(
                                              key as any,
                                              recordTypeList,
                                              recordStatusList,
                                              problemCodeList,
                                              usersList,
                                              targetTenantList
                                          );
                                    return (
                                        <RenderInput
                                            key={key}
                                            resetField={resetField}
                                            name={key}
                                            control={control}
                                            fieldData={value as IRecordField}
                                            errors={errors}
                                            options={options}
                                            isCustomList={isCustomList}
                                            disabled={!getEditableField(key, fieldAccessList, Number(recordTypeId), roleId)}
                                            isRequired={value?.required}
                                            setValue={setValue}
                                            handleOpenViewObjectPanel={handleOpenViewObjectPanel}
                                            inMemoryObjectProperties={inMemoryObjectProperties}
                                        />
                                    );
                                })}
                    </Box>
                </Grid>
            )}
        </>
    );
};

export default EditPanel;
