import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Collapse,
    FormControl,
    FormControlLabel,
    FormControlProps,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    NativeSelect,
    Select,
    SelectChangeEvent,
    Switch
} from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { Clear } from '@mui/icons-material';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';
import { useIntl } from 'react-intl';

export interface ListDropdownProps {
    onChange: (value: string | string[]) => void;
    listId?: number;
    listValues?: ListValue[];
    initialValue?: string | string[];
    variant?: FormControlProps['variant'];
    size?: FormControlProps['size'];
    label?: string;
    native?: boolean;
    multiple?: boolean;
    fromFilterPanel?: boolean;
}

export const ListDropdown = ({
    onChange,
    listId,
    listValues,
    initialValue,
    native,
    label,
    variant = 'outlined',
    size = 'medium',
    multiple = false,
    fromFilterPanel = false
}: ListDropdownProps) => {
    const intl = useIntl();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionMultipleSelected, setSelectedOptionMultipleSelected] = useState<string[]>([]);
    const [includeDisabledValues, setIncludeDisabledValues] = useState(false);
    const [getListValues, { data: listValuesData, loading }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_GET_LIST_VALUES);

    const optionList = useMemo(() => {
        if (listValues)
            return (
                listValues
                    // If initialValue is not enabled but should be shown
                    .filter((el) => {
                        const isEnabled = el.enabled;
                        const valueIsInInitialValue =
                            (typeof initialValue === 'string' && +el.id === +initialValue) ||
                            (Array.isArray(initialValue) && initialValue.map((val) => +val).includes(+el.id));

                        return isEnabled || (!isEnabled && includeDisabledValues) || valueIsInInitialValue;
                    })
                    .sort((a, b) => a.order - b.order) || []
            );
        if (listValuesData)
            return (
                listValuesData.findListValues
                    // If initialValue is not enabled but should be shown
                    .filter((el) => {
                        const isEnabled = el.enabled;
                        const valueIsInInitialValue =
                            (typeof initialValue === 'string' && +el.id === +initialValue) ||
                            (Array.isArray(initialValue) && initialValue.map((val) => +val).includes(+el.id));

                        return isEnabled || (!isEnabled && includeDisabledValues) || valueIsInInitialValue;
                    })
                    .sort((a, b) => a.order - b.order) || []
            );

        return [] as ListValue[];
    }, [includeDisabledValues, initialValue, listValues, listValuesData]);

    const optionsAsObjectWithIds: Record<number, ListValue> = useMemo(
        () => optionList.reduce((acc, el) => ({ ...acc, [el.id]: el }), {}),
        [optionList]
    );

    const disabledSelectedValuesList = optionList.filter(
        (el) =>
            !el.enabled &&
            ((typeof initialValue === 'string' && +initialValue === +el.id) ||
                (Array.isArray(initialValue) && initialValue.includes(el.id)))
    );

    const handleValueChange = (event: SelectChangeEvent<string | string[]> | React.ChangeEvent<{ value: string }>) => {
        const newValue = event.target.value;
        if (multiple) setSelectedOptionMultipleSelected(newValue as string[]);
        else setSelectedOption(newValue as string);
        onChange(newValue);
    };

    const handleClearClick = () => {
        const newValue = multiple ? ['0'] : '0';
        if (multiple) setSelectedOptionMultipleSelected([newValue] as string[]);
        else setSelectedOption(newValue as string);
        onChange(newValue);
    };

    // TODO: fix this memory leak: Can't perform a React state update on an unmounted component.
    const handleGetListValues = useCallback(async () => {
        try {
            let list = listValues || [];
            if (listId && !listValues?.length) {
                const { data } = await getListValues({ variables: { data: { listId } } });
                if (data?.findListValues) list = data.findListValues;
            }
            if (list.length) {
                if (multiple) {
                    const values = list.filter((el) => initialValue?.includes(el.id));
                    setSelectedOptionMultipleSelected(values?.map((val) => val.id || val.userValue?.id) || []);
                }
                if (!multiple) {
                    const value = list.find((el) => el.id === initialValue);
                    setSelectedOption(String(value?.id) || '');
                }
            }
        } catch (error) {
            console.log('error', error);
        }
    }, [getListValues, initialValue, listId, listValues, multiple]);

    useEffect(() => {
        handleGetListValues();
    }, [handleGetListValues]);

    return (
        <Box sx={{ minWidth: 149, width: '100%' }}>
            <FormControl fullWidth disabled={loading}>
                {label && (
                    <InputLabel id="problem-code-select" shrink>
                        {label}
                    </InputLabel>
                )}
                {native ? (
                    <NativeSelect value={selectedOption} onChange={handleValueChange}>
                        <option value=""> &nbsp;</option>
                        {optionList.map((el: any) => (
                            <option key={el.id} value={el.id}>
                                {!el.enabled && '(Disabled)'} {el.userValue?.name || el.value}
                            </option>
                        ))}
                    </NativeSelect>
                ) : (
                    <Select
                        value={multiple ? selectedOptionMultipleSelected : selectedOption}
                        onChange={handleValueChange}
                        variant={variant}
                        size={size}
                        sx={{ pt: '3px !important', '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                        multiple={multiple}
                        renderValue={(val) => {
                            if (typeof val === 'string')
                                return optionsAsObjectWithIds[+val].userValue?.name || optionsAsObjectWithIds[+val].value;
                            return val
                                .map((id) => optionsAsObjectWithIds[+id].userValue?.name || optionsAsObjectWithIds[+id].value)
                                .join(', ');
                        }}
                        endAdornment={
                            <IconButton
                                sx={{
                                    display: !multiple && selectedOption && selectedOption !== '0' ? '' : 'none'
                                }}
                                onClick={handleClearClick}
                            >
                                <Clear />
                            </IconButton>
                        }
                        {...(!multiple && selectedOption && selectedOption !== '0' && { IconComponent: () => null })}
                    >
                        {disabledSelectedValuesList.map((el) => (
                            <MenuItem disabled={!fromFilterPanel} key={el.id} value={el.id}>
                                {!el.enabled && '(Disabled)'} &nbsp;
                                {el.userValue?.name || el.value}
                            </MenuItem>
                        ))}

                        {optionList
                            .filter((option) => disabledSelectedValuesList.every((el) => +el.id !== +option.id))
                            .map((el) => (
                                <MenuItem key={el.id} value={el.id}>
                                    {!el.enabled && '(Disabled)'} &nbsp;
                                    {el.userValue?.name || el.value}
                                </MenuItem>
                            ))}
                    </Select>
                )}
                <Collapse in={loading}>
                    <LinearProgress />
                </Collapse>
            </FormControl>
            {fromFilterPanel && (
                <FormControlLabel
                    control={
                        <Switch
                            color="secondary"
                            checked={includeDisabledValues}
                            onChange={(e) => setIncludeDisabledValues(e.target.checked)}
                            size="small"
                        />
                    }
                    sx={{ pl: '4px', '& span': { fontSize: '13px' } }}
                    label={intl.formatMessage({ id: 'showDisabledValues' })}
                />
            )}
        </Box>
    );
};
