import {
    FilterAndSortAdditionalFields,
    FilterCheckboxCriteriaEnum,
    FilterDate,
    FilterDateCriteriaEnum,
    FilterNumber,
    FilterNumberCriteriaEnum,
    FilterObject,
    FilterObjectCriteriaEnum,
    FilterRecordHeaderInput,
    FilterString,
    FilterStringCriteriaEnum,
    IRecordField,
    IRecordFieldWithFieldName,
    IRecordFieldsList,
    IRecordHeaders,
    SortRecords,
    filterOperatorEnum,
    orderCriteriaEnum
} from 'ui-component/records/types';
import { getBaseOperators, getCheckboxOperators, getRecordGridNumericOperators, getRecordGridStringOperators } from './GridOperatorList';
import { filterOperatorList } from './filterOperatorList';
import { GridFilterItem, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';

/**
 * Non filterable or sortable fields define from the database
 */
export const NON_FILTERABLE_OR_SORTABLE_FIELDS = ['tenant', 'recordType', 'enabled'];

/**
 * Non sortable data types
 */
export const NON_SORTABLE_DATA_TYPES = ['multiselect', 'multiselect-checkbox'];

/**
 * Non editable data types
 */
export const NON_EDITABLE_DATA_TYPES = ['object'];

/**
 *
 * Return the operators to filter depending on data type
 *
 * @param key {String} should be key of IRecordHeaders
 * @param recordTypeId {Number}
 * @param headerField {IRecordField}
 * @returns
 */
export const getFilterOperator = (key: string, recordTypeId: number, headerField: IRecordField) => {
    const { dataType, listType } = headerField;
    let operatorList = getBaseOperators();
    const customOperators = filterOperatorList[key as keyof IRecordHeaders]?.(recordTypeId);
    const numberLikeTypes = ['number', 'decimal'];
    const stringLikeTypes = ['string', 'textarea', 'url', 'object'];

    if (numberLikeTypes.includes(dataType)) operatorList.push(...getRecordGridNumericOperators());
    if (stringLikeTypes.includes(dataType.toLowerCase())) operatorList.push(...getRecordGridStringOperators());
    if (dataType === 'date') operatorList.push(...filterOperatorList.date());
    if (dataType === 'currency') operatorList.push(...filterOperatorList.currency());
    if (dataType === 'datetime') operatorList.push(...filterOperatorList.dateTime());
    if (dataType === 'time') operatorList.push(...filterOperatorList.time());
    if (dataType === 'dropdown' && listType) operatorList.push(...filterOperatorList.customList(listType.id));
    if ((dataType === 'multiselect' || dataType === 'multiselect-checkbox') && listType)
        operatorList.push(...filterOperatorList.customMultiList(listType.id));
    if (dataType === 'checkbox') operatorList = getCheckboxOperators();
    if (customOperators) operatorList.push(...customOperators);

    return operatorList;
};

/**
 *  Remap keys for some fields
 *
 * Some Fields doesn't have the field key that they supposed to have in order
 * to send it to the request.
 *
 * @param header {String} should be a key of IRecordHeaders
 * @returns
 */
const remapHeaders = (header: string) => {
    if ((header as keyof IRecordHeaders) === 'statusId') return 'status';
    if ((header as keyof IRecordHeaders) === 'problemCodeId') return 'problemCode';

    return header as keyof FilterRecordHeaderInput;
};

/**
 * Generate a filters and sort object to be send it to the request
 *
 * @param filterModel {GridFilterModel}
 * @param sortModel {GridSortModel}
 * @param headersData {IRecordHeaders}
 * @returns
 */
export const generateFilterPayloads = (filterModel: GridFilterModel, sortModel: GridSortModel, headersData?: IRecordFieldsList) => {
    if (!headersData) return undefined;

    const filters: FilterRecordHeaderInput = {};

    const { aditionalFields, baseFields } = splitHeadersData(headersData);
    const logicOperator = filterModel.logicOperator?.toLocaleUpperCase() as filterOperatorEnum;

    baseFields.forEach(({ fieldName, ...headerField }, idx) => {
        const filterItems = getFilterItemsByField(fieldName, filterModel);
        const mappedFieldName = remapHeaders(fieldName);

        const dataType = headerField.dataType;
        const filterObj = generateFilters(filterItems, dataType, logicOperator, idx);
        const sortObj = generateSortObject(fieldName, sortModel);

        // We should not keep it in the filters object
        // if doesn't have either filters or sort options
        if (!filterItems.length && !sortObj) return;

        // TODO: check this type error
        // @ts-ignore
        filters[mappedFieldName as keyof Omit<FilterRecordHeaderInput, 'enabled' | 'ids'>] = {
            filters: filterObj,
            sort: sortObj
        };
    });

    aditionalFields.forEach(({ fieldName, ...headerField }, idx) => {
        const filterItems = getFilterItemsByField(fieldName, filterModel);
        const mappedFieldName = remapHeaders(fieldName);
        // For backend purposes this always have to be string for aditional fields
        const dataType = 'string';
        const fieldId = Number(headerField.id);
        const filterObj = generateFilters(filterItems, dataType, logicOperator, idx);
        const sortObj = generateSortObject(mappedFieldName, sortModel);

        // We should not keep it in the filters object
        // if doesn't have either filters or sort options
        if (!filterItems.length && !sortObj) return;

        filters.additionalFields = [
            ...(filters.additionalFields || []),
            {
                recordAdditionalFieldByTypeId: fieldId,
                filters: filterObj,
                sort: sortObj
            } as unknown as FilterAndSortAdditionalFields
        ];
    });

    return filters;
};

/**
 * Check if the value exists and if is required to send the filter
 *
 * @param filterModel {GridFilterModel}
 * @returns
 */
export const checkFilterValue = (filterModel: GridFilterModel) => {
    const isValidFilter = filterModel.items?.every((item) => {
        const hasValue = item.value?.length;

        const isNotValid = Array.isArray(item.value) ? item.value.length === 0 : item.value?.match(/;|'|--|\/\*|\*\//);
        const canBeUndefined = [
            FilterStringCriteriaEnum.isEmpty,
            FilterStringCriteriaEnum.isNotEmpty,
            FilterCheckboxCriteriaEnum.isChecked,
            FilterCheckboxCriteriaEnum.isNotChecked
        ].includes(item.operator as FilterStringCriteriaEnum);

        const haveMixedData =
            (!Array.isArray(item.value) && item.operator === FilterStringCriteriaEnum.isAnyOf) ||
            (Array.isArray(item.value) && item.operator !== FilterStringCriteriaEnum.isAnyOf && !canBeUndefined);

        if ((hasValue && !haveMixedData && !isNotValid) || (!hasValue && canBeUndefined)) return true;
        return false;
    });

    return isValidFilter;
};

/**
 * Returns the fiter items for given field name
 *
 * @param field {String}
 * @param filterModel {GridFilterModel}
 * @returns
 */
export const getFilterItemsByField = (field: string, filterModel: GridFilterModel) =>
    filterModel.items.filter((filterItem) => filterItem.field.toLocaleLowerCase() === field.toLocaleLowerCase());

/**
 * Split the headers data in baseFields and additionalFields
 *
 * @param headersData {IRecordFieldList}
 * @returns
 */
export const splitHeadersData = (headersData: IRecordFieldsList) => {
    const baseFields: IRecordFieldWithFieldName[] = [];
    const aditionalFields: IRecordFieldWithFieldName[] = [];

    for (const fieldName in headersData) {
        if (Object.prototype.hasOwnProperty.call(headersData, fieldName)) {
            const element = headersData[fieldName];
            if (Number(element.id) === 0) baseFields.push({ ...element, fieldName });
            else aditionalFields.push({ ...element, fieldName });
        }
    }

    return { baseFields, aditionalFields };
};

/**
 * Generate the Filters array depending on its data type
 *
 * @param filterItems {GridFilterItem[]}
 * @param dataType {IRecorfField['dataType']}
 * @param logicOperator {filterOperatorEnum}
 * @param priority {Number}
 * @returns
 */
export const generateFilters = (
    filterItems: GridFilterItem[],
    dataType: IRecordField['dataType'],
    logicOperator: filterOperatorEnum,
    priority: number
) => {
    switch (dataType.toLowerCase()) {
        case 'number':
        case 'decimal':
            return filterItems.map<FilterNumber>(({ operator, value }) => ({
                logicOperator,
                priority,
                filterCriteria: operator as FilterNumberCriteriaEnum,
                value: Array.isArray(value) ? undefined : Number(value),
                values: Array.isArray(value) ? value : undefined
            }));

        case 'dropdown':
            return filterItems.map<FilterObject>(({ operator, value }) => ({
                logicOperator,
                priority,
                filterCriteria: operator as FilterObjectCriteriaEnum,
                value: Array.isArray(value) ? undefined : Number(value) || 0,
                values: Array.isArray(value) ? value.map((el) => Number(el)) : undefined
            }));

        case 'date':
            return filterItems.map<FilterDate>(({ operator, value }) => ({
                logicOperator,
                priority,
                filterCriteria: operator as FilterDateCriteriaEnum,
                value
            }));

        default:
            return filterItems.map<FilterString>(({ operator, value }) => ({
                logicOperator,
                priority,
                filterCriteria: operator as FilterStringCriteriaEnum,
                value: Array.isArray(value) ? undefined : String(value),
                values: Array.isArray(value) ? value : undefined
            }));
    }
};

/**
 * Generate the sort record obj for given field
 *
 * @param field {String}
 * @param sortModel {GridSortModel}
 * @returns SortRecords or undefined
 */
export const generateSortObject = (field: string, sortModel: GridSortModel) => {
    const sortObjIndex = sortModel.findIndex((el) => el.field.toLocaleLowerCase() === field.toLocaleLowerCase());

    if (sortObjIndex === -1) return undefined;

    return {
        priority: sortObjIndex,
        orderCriteria: sortModel[sortObjIndex].sort === 'asc' ? orderCriteriaEnum.ASC : orderCriteriaEnum.DESC
    } as SortRecords;
};
