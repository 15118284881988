import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Chip, Collapse, FormControl, LinearProgress, TextField } from '@mui/material';
import { useQuery } from '@apollo/client';
import { QUERY_FIND_LIST_VALUES } from 'graphql/queries/lists';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';
import { Clear } from '@mui/icons-material';
import { v4 as UIDV4 } from 'uuid';

export interface MultiselectEditCellProps {
    onChange: (value: string) => void;
    listId: number;
    initialValue?: string;
}

export const MultiselectEditCell = ({ onChange, listId, initialValue }: MultiselectEditCellProps) => {
    const [selectedOption, setSelectedOption] = useState<ListValue[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const buttonId = UIDV4();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setAnchorEl(document.getElementById(buttonId));
    }, [buttonId]);

    const handleOpen = (event: React.SyntheticEvent<Element, Event>) => {
        setOpen(true);
    };

    const { data: listData, loading } = useQuery<FindListValues, FindListValuesVariables>(QUERY_FIND_LIST_VALUES, {
        variables: { data: { listId } },
        onCompleted(data) {
            let initialValueKeys: number[] = [];
            if (initialValue) {
                initialValueKeys = JSON.parse(initialValue);
            }
            setSelectedOption(initialValue ? data.findListValues.filter((item) => initialValueKeys.includes(+item.id)) : []);
        }
    });

    const optionList = listData?.findListValues.filter((el) => el.enabled).sort((a, b) => a.order - b.order) || [];

    return (
        <Box sx={{ minWidth: 250 }}>
            <FormControl fullWidth disabled={loading}>
                <Autocomplete
                    multiple
                    limitTags={1}
                    id={`multiselect-edit-cell-${UIDV4()}`}
                    openOnFocus
                    renderTags={(value, getTagProps, ownerState) =>
                        value.map((option: any, index: number) => (
                            <Chip
                                sx={(theme) => ({
                                    backgroundColor: `#203461 !important`,
                                    color: `#fff !important`,
                                    borderRadius: '20px !important',
                                    width: '140px !important'
                                })}
                                label={option.userValue?.name || option.value || ''}
                                deleteIcon={<Clear />}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    value={selectedOption}
                    onChange={(_e, value) => {
                        onChange(value.length === 0 ? '0' : JSON.stringify(value.map((val: any) => +val.id)));
                        setSelectedOption(value);
                    }}
                    onOpen={handleOpen}
                    onClose={() => setOpen(false)}
                    options={optionList}
                    slotProps={{
                        popper: {
                            anchorEl,
                            open: open && Boolean(anchorEl),
                            placement: 'bottom'
                        }
                    }}
                    componentsProps={{
                        popupIndicator: {
                            id: buttonId
                        }
                    }}
                    getOptionLabel={(option: any) => option && (option.userValue?.name || option.value || '')}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} />}
                />
                <Collapse in={loading}>
                    <LinearProgress />
                </Collapse>
            </FormControl>
        </Box>
    );
};
