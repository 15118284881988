import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';
import { useEffect, useMemo } from 'react';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';

export type DropdownRenderProps = {
    value: string;
    listValues?: ListValue[];
    listId?: number;
};

export const DropdownRender = ({ listId, listValues, value }: DropdownRenderProps) => {
    const [getListValues, { data: listValuesData }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_GET_LIST_VALUES, {
        variables: { data: { listId: Number(listId) } }
    });

    const values = useMemo(() => {
        if (listValues) return listValues;
        if (listValuesData) return listValuesData.findListValues || [];
        return [] as ListValue[];
    }, [listValues, listValuesData]);

    const selectedOption = values.find((item) => Number(item.id) === Number(value));

    useEffect(() => {
        if (listId && !listValues?.length) getListValues();
    }, [listId, getListValues, listValues?.length]);

    return <>{selectedOption?.userValue?.name || selectedOption?.value || ''}</>;
};
