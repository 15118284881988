import React, { ReactNode, useState } from 'react';
import { ThemeModeContextType } from 'hooks/useThemeMode';

export const ThemeModeContext = React.createContext<ThemeModeContextType>({} as ThemeModeContextType);

export interface IDialogData {
    // title: () => JSX.Element;
    title: string;
    icon?: JSX.Element;
    onForward: (() => Promise<void>) | null;
    onAction: (() => void) | null;
    content: string;
    forwardButtonText?: string;
    actionButtonText?: string;
    loadingText?: string;
    showCheckbox: boolean;
    sectionTitle: string;
    actionType: string;
    recordType: string;
}
export const ThemeModeContextProvider = ({ children }: { children?: ReactNode | undefined }) => {
    const [themeMode, setThemeMode] = useState<'light' | 'dark'>((localStorage.getItem('themeMode') as 'light' | 'dark') || 'light');

    const setThemeModeHandler = (val: 'light' | 'dark') => {
        setThemeMode(val);
        localStorage.setItem('themeMode', val);
    };

    return (
        <ThemeModeContext.Provider value={{ setThemeMode: setThemeModeHandler, themeMode } as ThemeModeContextType}>
            {children}
        </ThemeModeContext.Provider>
    );
};
