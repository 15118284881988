import { Avatar, Dialog, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { AccountCircle, CancelOutlined } from '@mui/icons-material';
import { AuditLog } from '../types';
import { getActionIcon } from '../PropertiesPanel/LogReportPanel/utils';
import { Fragment } from 'react';
import { getAuditFormattedValue } from '../utils';

export type DetailsDialogProps = {
    item: AuditLog | null;
    onClose: () => void;
};

export const DetailsDialog = ({ onClose, item }: DetailsDialogProps) => (
    <Dialog open={!!item} onClose={onClose} maxWidth="xl">
        {!!item && (
            <>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ color: '#ffff', backgroundColor: '#B5B5B5', height: '30px', width: '30px' }}>
                            {getActionIcon(item.action, 'small')}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography textTransform="capitalize">
                                Record Data <FormattedMessage id={item.action} />
                            </Typography>
                        }
                    />

                    <IconButton onClick={onClose}>
                        <CancelOutlined color="secondary" />
                    </IconButton>
                </ListItem>
                <ListItemText style={{ marginLeft: 55, marginRight: 50 }}>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    On <br />
                                    {format(new Date(item.datetime), 'MMM d, y')}
                                    <br />
                                    {format(new Date(item.datetime), 'h:mmaaaa')}
                                </>
                            }
                        />
                    </ListItem>

                    <ListItem style={{ paddingRight: 40 }}>
                        <ListItemAvatar>
                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                <AccountCircle />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${item.user.firstName} ${item.user.lastName}`} secondary={item.user.email} />
                    </ListItem>

                    {item.action === 'update' && (
                        <>
                            {item.changes.map((el, idx) => (
                                <Fragment key={`${el.what}-${idx}`}>
                                    <ListItemText
                                        style={{ paddingLeft: 10 }}
                                        primary={
                                            <Typography>
                                                <Typography component="span" textTransform="capitalize">
                                                    <FormattedMessage id={item.action} />
                                                </Typography>
                                                &nbsp;
                                                <FormattedMessage id="theFollowingInformationOn" />
                                                &nbsp;
                                                <Typography component="span" textTransform="capitalize">
                                                    {el.what}
                                                </Typography>
                                            </Typography>
                                        }
                                    />

                                    <ListItem style={{ paddingLeft: 10, alignItems: 'flex-start' }}>
                                        <ListItemText
                                            style={{ paddingRight: 30 }}
                                            primary={
                                                <Typography textTransform="capitalize">
                                                    <FormattedMessage id="before" />
                                                </Typography>
                                            }
                                            secondary={
                                                <div style={{ color: 'black' }}>
                                                    <Typography
                                                        component="span"
                                                        dangerouslySetInnerHTML={{ __html: getAuditFormattedValue(el.oldValue) }}
                                                    />
                                                </div>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Typography textTransform="capitalize">
                                                    <FormattedMessage id="now" />
                                                </Typography>
                                            }
                                            secondary={
                                                <div style={{ color: 'black' }}>
                                                    <Typography
                                                        component="span"
                                                        dangerouslySetInnerHTML={{ __html: getAuditFormattedValue(el.newValue) }}
                                                    />
                                                </div>
                                            }
                                        />
                                    </ListItem>
                                </Fragment>
                            ))}
                        </>
                    )}
                </ListItemText>
            </>
        )}
    </Dialog>
);

export default DetailsDialog;
