import { Avatar, Button, Card, CardHeader, Grid, Typography } from '@mui/material';
import React from 'react';

export type TenantCardProps = {
    onClick?: () => void;
    name: string;
    description: string;
    selected?: boolean;
};

export const TenantCard = ({ description, name, onClick, selected = false }: TenantCardProps) => (
    <Button onClick={onClick} disableRipple disableTouchRipple disableFocusRipple fullWidth>
        <Card
            sx={(theme) => ({
                bgcolor: selected ? theme.palette.secondary.main : 'white',
                width: '100%',
                height: '44px',
                borderRadius: '8px',
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
            })}
            raised
        >
            <CardHeader
                sx={{
                    maxWidth: 'calc(100% - 48px)',
                    py: 0,
                    '& .MuiCardHeader-content': { width: '100%' },
                    '& .MuiCardHeader-avatar ': { width: '27px', height: '27px' }
                }}
                avatar={
                    <Avatar
                        sx={(theme) => ({
                            p: '15px',
                            bgcolor: 'white',
                            color: 'black',
                            border: 'solid 2px',
                            borderColor: theme.palette.secondary.main,
                            width: '27px',
                            height: '27px'
                        })}
                    >
                        {name[0]}
                    </Avatar>
                }
                title={
                    <Grid container alignItems="center">
                        <Grid item xs="auto">
                            <Typography color={selected ? 'white' : 'inherit'} variant="h6" fontSize="16px" fontWeight={500} align="left">
                                {name}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs="auto">
                            <Typography
                                color={selected ? 'white' : 'inherit'}
                                variant="h6"
                                fontSize="11px"
                                fontWeight={200}
                                sx={{ lineHeight: 1 }}
                            >
                                &nbsp;•&nbsp;{description}
                            </Typography>
                        </Grid> */}
                    </Grid>
                }
                subheader={
                    <Typography
                        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                        color={selected ? 'white' : 'inherit'}
                        variant="body1"
                        fontSize="11px"
                        fontWeight={275}
                        align="left"
                    >
                        {description}
                    </Typography>
                }
            />
        </Card>
    </Button>
);
