/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const QUERY_GET_RECORD_FIELDS = gql`
    query Query($recordType: Float!) {
        getSaasRecordFieldsByRecordTypes(RecordType: $recordType)
    }
`;

export const QUERY_GET_RECORD_HEADERS = gql`
    query FindRecordHeader($data: RecordHeaderFindInput!, $pagination: PaginationInputType) {
        findRecordHeader(data: $data, pagination: $pagination) {
            records {
                id
                tenant {
                    name
                    id
                }
                recordType {
                    id
                    name
                }
                recordNumber
                statusId {
                    id
                    name
                }
                problemCodeId {
                    id
                    name
                }
                enabled
                createdBy {
                    id
                    name
                }
                createdAt
                receivedBy {
                    name
                    id
                }
                receivedAt
                approvedBy {
                    name
                    id
                }
                approvedAt
                assignedTo {
                    id
                    name
                }
                updatedAt
                recordFile {
                    id
                }
                targetTransmissionTenant {
                    id
                    name
                }
                additionalFields {
                    id
                    objectValue {
                        id
                        objectValues {
                            value
                            tag
                            objectProperty {
                                id
                                name
                                isDisplayable
                                dataType
                                order
                            }
                        }
                    }
                    tag
                    value
                }
            }
            total
        }
    }
`;

export const QUERY_FIND_RECORD_HEADERS_WITH_FILTERING = gql`
    query FilterAndSortRecordHeader($data: FilterAndSortRecordHeaderInput!, $pagination: PaginationInputType) {
        filterAndSortRecordHeader(data: $data, pagination: $pagination) {
            records {
                id
                enabled
                createdBy {
                    id
                    name
                    email
                }
                problemCodeId {
                    id
                    name
                }
                receivedAt
                receivedBy {
                    id
                    name
                    email
                }
                recordNumber
                recordFile {
                    id
                }
                recordType {
                    id
                    name
                }
                statusId {
                    id
                    name
                }
                targetTenant
                tenant {
                    id
                    name
                }
                updatedAt
                createdAt
                assignedTo {
                    id
                    name
                    email
                }
                approvedBy {
                    email
                    name
                    id
                }
                approvedAt
                targetTransmissionTenant {
                    id
                    name
                }
                additionalFields {
                    id
                    objectValue {
                        id
                        objectValues {
                            value
                            tag
                            objectProperty {
                                id
                                name
                                isDisplayable
                                dataType
                                order
                            }
                        }
                    }
                    tag
                    value
                }
            }
            total
        }
    }
`;

export const QUERY_GET_USER_PREFERENCES = gql`
    query FindUserGridPreferences($data: UserGridPreferencesFindInput!) {
        findUserGridPreferences(data: $data) {
            id
            tenantId
            gridName
            enabled
            savedName
            isDefault
            gridOptions {
                orderedFields
                hiddenFields
                filterModel {
                    columnField
                    operatorValue
                    value
                }
                pinnedColumns {
                    left
                    right
                }
                densityFactor
                pageSize
            }
        }
    }
`;

export const PREFERENCES_BACKOFFICE = gql`
    query Query($gridName: String!) {
        SaasMyGridPreferences(gridName: $gridName)
    }
`;

export const QUERY_GET_ROLE_PREFERENCES = gql`
    query SaasMyRoleGridPreferences {
        SaasMyRoleGridPreferences {
            createdAt
            enabled
            gridName
            id
            isDefault
            savedName
            updatedAt
            gridOptions {
                orderedFields
                hiddenFields
                filterModel {
                    value
                    operatorValue
                    columnField
                }
                pinnedColumns {
                    left
                    right
                }
                pageSize
                densityFactor
            }
        }
    }
`;

export const QUERY_GET_ROLE_ACCESS = gql`
    query GetRoleAccessToRecordFields {
        getRoleAccessToRecordFields {
            access
            enabled
            id
            order
            recordField {
                id
                name
            }
            recordFieldName
            recordType {
                id
                name
            }
            updatedAt
            role {
                id
                name
            }
        }
    }
`;

export const QUERY_FIND_ROLE_ACCESS = gql`
    query FindRoleAccessToRecordFields($data: RoleAccessToRecordFieldsFindInput!) {
        findRoleAccessToRecordFields(data: $data) {
            access
            enabled
            id
            order
            recordField {
                id
                name
            }
            recordFieldName
            recordType {
                id
                name
            }
            updatedAt
            role {
                id
                name
            }
        }
    }
`;

export const QUERY_FIND_TARGET_TENANTS = gql`
    query GetTargetTenantsByRecordType($data: GetTargetTenantsInput!) {
        getTargetTenantsByRecordType(data: $data) {
            mappingDetails {
                recordTypeToTransmit {
                    id
                    name
                }
                transferSettings {
                    sourceErrorStatusValue
                    sourceStatusField {
                        id
                        name
                    }
                    sourceSuccessStatusValue
                    targetRecordType {
                        id
                        name
                    }
                    targetStatusField {
                        id
                        name
                    }
                    targetStatusValue
                    triggerField {
                        id
                        name
                    }
                    triggerValue
                }
            }
            targetTenantToTransmit {
                id
                name
            }
        }
    }
`;
