import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { AuditLog } from 'ui-component/RecordView/types';
import { getActionIcon } from '../utils';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

export type LogItemProps = {
    item: AuditLog;
    onOpen: () => void;
};

export const LogItem = ({ onOpen, item }: LogItemProps) => (
    <ListItemButton onClick={onOpen} sx={{ '& :hover p, :hover span': { color: '#FFF !important' } }}>
        <ListItemAvatar>
            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>{getActionIcon(item.action)}</Avatar>
        </ListItemAvatar>
        <ListItem sx={{ px: 0 }}>
            <ListItemText
                primary={
                    <Typography textTransform="capitalize">
                        <FormattedMessage id={item.action} />
                    </Typography>
                }
                secondary={
                    <>
                        <Typography component="span" variant="body2">{`${item.user.firstName} ${item.user.lastName}`}</Typography>
                        <br />
                        {format(new Date(item.datetime), 'MMM d, y - h:mmaaaa')}
                    </>
                }
            />
        </ListItem>
    </ListItemButton>
);
