import { io } from 'socket.io-client';
import { SOCKET_URL } from 'config';
import { createContext } from 'react';

export const socket = io(SOCKET_URL, {
    autoConnect: false
});

socket.connect();
export const SocketContext = createContext<any>(null);
