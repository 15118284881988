import React from 'react';
import { FileDownload, ModeEdit, QuestionMark, Visibility } from '@mui/icons-material';

/**
 * Returns the icon for the action given
 *
 * @param action {String} action name saved in the log
 * @param fontSize {String} font size according to MUI Icon specification
 * @returns
 */
export const getActionIcon = (action: string, fontSize: 'inherit' | 'large' | 'medium' | 'small' = 'medium') => {
    switch (action) {
        case 'update':
            return <ModeEdit fontSize={fontSize} />;
        case 'view':
            return <Visibility fontSize={fontSize} />;
        case 'download':
            return <FileDownload fontSize={fontSize} />;
        default:
            return <QuestionMark fontSize={fontSize} />;
    }
};
