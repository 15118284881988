import { Close, DragIndicator, ExpandMore, TextRotateVertical } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';

export type GridToolbarProps = {
    ToolbarButtons?: () => JSX.Element;
    rowReorder?: boolean;
    onSaveReorder?: () => void;
    toggleReorder?: () => void;
    onAlphabeticalOrder?: () => void;
    isReordering?: boolean;
    showQuickFilter?: boolean;
};

export const GridToolbar = ({
    ToolbarButtons,
    onSaveReorder,
    onAlphabeticalOrder,
    rowReorder,
    toggleReorder,
    isReordering,
    showQuickFilter
}: GridToolbarProps) => (
    <GridToolbarContainer>
        {showQuickFilter && (
            <GridToolbarQuickFilter
                variant="filled"
                size="small"
                placeholder="Find in current page"
                InputProps={{
                    disableUnderline: true,
                    sx: ({ palette }) => ({
                        borderRadius: '30px',
                        backgroundColor: palette.primary[300],
                        '&  input': { py: '10px' },
                        '&  .MuiSvgIcon-root ': { color: palette.primary[400] }
                    })
                }}
            />
        )}
        <GridToolbarColumnsButton
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px'
            })}
            endIcon={<ExpandMore />}
        />
        <GridToolbarFilterButton
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px'
            })}
            componentsProps={{ button: { endIcon: <ExpandMore /> } }}
        />
        <GridToolbarDensitySelector
            sx={({ palette }) => ({
                color: palette.primary[400],
                backgroundColor: palette.primary[300],
                borderRadius: '30px',
                padding: '8px'
            })}
            endIcon={<ExpandMore />}
        />
        <GridToolbarExport
            sx={(theme: { palette: { primary: any[] } }) => ({
                color: theme.palette.primary[400],
                backgroundColor: theme.palette.primary[300],
                borderRadius: '30px',
                padding: '8px'
            })}
            endIcon={<ExpandMore />}
        />
        {ToolbarButtons && <ToolbarButtons />}
        {!!rowReorder && isReordering && (
            <>
                <Button
                    onClick={onSaveReorder}
                    sx={({ palette }) => ({
                        color: palette.primary[400],
                        backgroundColor: palette.primary[300],
                        borderRadius: '30px',
                        padding: '8px'
                    })}
                    startIcon={<DragIndicator />}
                    size="medium"
                    color="primary"
                >
                    Save order
                </Button>
                {onAlphabeticalOrder && (
                    <Button
                        onClick={onAlphabeticalOrder}
                        sx={({ palette }) => ({
                            color: palette.primary[400],
                            backgroundColor: palette.primary[300],
                            borderRadius: '30px',
                            padding: '8px'
                        })}
                        startIcon={<TextRotateVertical />}
                        size="medium"
                        color="primary"
                    >
                        Alphabetical order
                    </Button>
                )}
                <Button
                    onClick={toggleReorder}
                    sx={({ palette }) => ({
                        color: palette.primary[400],
                        backgroundColor: palette.primary[300],
                        borderRadius: '30px',
                        padding: '8px'
                    })}
                    startIcon={<Close />}
                    size="medium"
                    color="primary"
                >
                    Cancel
                </Button>
            </>
        )}
        {!!rowReorder && !isReordering && (
            <Button
                onClick={toggleReorder}
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px'
                })}
                startIcon={<DragIndicator />}
                size="medium"
                color="primary"
            >
                Edit order
            </Button>
        )}
    </GridToolbarContainer>
);
