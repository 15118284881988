import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import 'react-chat-widget/lib/styles.css';
import { Worker, Viewer, Button, ScrollMode, ViewMode } from '@react-pdf-viewer/core';
import { BorderColor } from '@mui/icons-material';
import {
    highlightPlugin,
    RenderHighlightsProps,
    RenderHighlightTargetProps,
    RenderHighlightContentProps
} from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { Box } from '@mui/material';
import { Pagination, Zoom } from '../components';
import { IHighLightPart } from '../types';

interface IPdfViewerProps {
    initialParts?: IHighLightPart[];
    rotateInstance: any;
    printInstance?: any;
    searchInstance?: any;
    url: string;
}

export type PdfViewerRef = {
    getParts: () => IHighLightPart[];
};

export const PdfViewer = forwardRef(({ initialParts = [], rotateInstance, printInstance, searchInstance, url }: IPdfViewerProps, ref) => {
    const [parts, setParts] = useState<IHighLightPart[]>([...initialParts]);

    const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
        <div
            style={{
                background: '#D9D9D9',
                display: 'flex',
                position: 'absolute',
                left: `${props.selectionRegion.left}%`,
                top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                transform: 'translate(0, 8px)',
                zIndex: 99
            }}
        >
            <Button onClick={props.toggle}>
                <BorderColor />
            </Button>
        </div>
    );

    const renderHighlightContent = (props: RenderHighlightContentProps) => {
        const onClickHere = () => {};
        const itExist = !!parts.find(
            (area) => JSON.stringify(area.highlightAreas) === JSON.stringify(props.highlightAreas) && area.quote === props.selectedText
        );
        setParts(
            itExist
                ? parts.filter((area) => area.quote !== props.selectedText)
                : [...parts, { highlightAreas: props.highlightAreas, quote: props.selectedText }]
        );
        props.cancel();
        // };
        return (
            <div
                style={{
                    background: '#D9D9D9',
                    display: 'flex',
                    position: 'absolute',
                    left: `${props.selectionRegion.left}%`,
                    top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                    transform: 'translate(0, 8px)',
                    zIndex: 99
                }}
            >
                <Button onClick={onClickHere}>
                    <BorderColor />
                </Button>
            </div>
        );
    };

    const renderHighlights = (props: RenderHighlightsProps) => (
        <div>
            {parts &&
                parts.map((part) => (
                    <React.Fragment key={part.quote}>
                        {part.highlightAreas
                            // Filter all highlights on the current page
                            .filter((el: any) => el.pageIndex === props.pageIndex)
                            .map((el: any, idx: number) => (
                                <div
                                    key={idx}
                                    style={{
                                        background: 'yellow',
                                        opacity: 0.4,
                                        ...props.getCssProperties(el, props.rotation)
                                    }}
                                />
                            ))}
                    </React.Fragment>
                ))}
        </div>
    );
    const pageNavigationPluginInstance = pageNavigationPlugin();

    const zoomPluginInstance = zoomPlugin();
    const { CurrentScale, ZoomIn, ZoomOut, Zoom: ZoomComponent } = zoomPluginInstance;
    const { CurrentPageLabel, GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage, NumberOfPages, jumpToPage } =
        pageNavigationPluginInstance;

    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget,
        renderHighlights,
        renderHighlightContent
    });

    const PdfViewerPlugins = useMemo(() => {
        const arr = [zoomPluginInstance, highlightPluginInstance, pageNavigationPluginInstance, rotateInstance];
        if (printInstance) arr.push(printInstance);
        if (searchInstance) arr.push(searchInstance);
        return arr;
    }, [highlightPluginInstance, pageNavigationPluginInstance, printInstance, rotateInstance, searchInstance, zoomPluginInstance]);

    useImperativeHandle(ref, () => ({
        getParts: () => parts
    }));
    return (
        <>
            {/* @ts-ignore */}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Box
                    id="pdf-viwer-container"
                    sx={(theme) => ({
                        height: '100%',
                        position: 'relative',
                        backgroundColor: `${theme.palette.primary[300]} !important`,
                        '& .rpv-core__inner-page': {
                            backgroundColor: `${theme.palette.primary[300]} !important`
                        },
                        '& .rpv-core__viewer': {
                            height: 'calc(100% - 65px) !important'
                        }
                    })}
                >
                    {/* @ts-ignore */}
                    <Viewer
                        scrollMode={ScrollMode.Vertical}
                        viewMode={ViewMode.SinglePage}
                        pageLayout={{
                            transformSize: ({ size }) => ({
                                height: size.height + 30,
                                width: size.width + 30
                            })
                        }}
                        plugins={PdfViewerPlugins}
                        fileUrl={url}
                    />
                    <Pagination
                        CurrentPageLabel={CurrentPageLabel}
                        GoToFirstPage={GoToFirstPage}
                        GoToLastPage={GoToLastPage}
                        GoToNextPage={GoToNextPage}
                        GoToPreviousPage={GoToPreviousPage}
                        NumberOfPages={NumberOfPages}
                        jumpToPage={jumpToPage}
                    />
                    <Zoom ZoomIn={ZoomIn} ZoomOut={ZoomOut} CurrentScale={CurrentScale} ZoomComponent={ZoomComponent} />
                </Box>
            </Worker>
        </>
    );
});

export default PdfViewer;
