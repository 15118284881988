/* eslint-disable no-case-declarations */
import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'store';
import useMyRole from 'hooks/useMyRole';
import { Box, IconButton, SelectChangeEvent, Tooltip } from '@mui/material';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { MUTATION_CREATE_USER_PREFERENCES, MUTATION_UPDATE_USER_PREFERENCES } from 'graphql/mutations/bills';
import { PREFERENCES_BACKOFFICE } from 'graphql/queries/bills';
import { openSnackbar } from 'store/slices/snackbar';
import { IUserDataToken } from 'utils/types';
import {
    IGridPreference,
    IRegisterUserPreference,
    ISaasMyGridPreference,
    IUpdateGridPreference,
    UserGridPreferencesRegisterVariables,
    UserGridPreferencesUpdateVariables
} from '../types';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import SaveNameDialog from '../SaveNameDialog';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { generateGridOptionsToSave } from '../utils/saveGridHelpers';
import { PreferenceSelect, SearchBox } from './components';
import { ChangeListType, getDefaultGrid, getUpdatedPreferenceList } from './utils';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { Search } from '@mui/icons-material';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save.svg';
import { ReactComponent as SaveNewIcon } from 'assets/images/icons/savenew.svg';

export interface IGridOptionsComponent {
    onPreferenceSave: () => void;
    showSaveChanges: boolean;
    onLoading: (loading: boolean) => void;
    setDefaultGrid: (preference: IGridPreference | null) => void;
    inputValue: string;
    onChangeInputValue: (value: string) => void;
    apiRef?: React.MutableRefObject<GridApiPro>;
    densityFactor: React.MutableRefObject<number>;
    createOrder: () => void;
    loadPreferences?: boolean;
    [key: string]: any;
}

export const GridOptions = ({
    onPreferenceSave,
    showSaveChanges,
    inputValue,
    onChangeInputValue,
    onLoading,
    setDefaultGrid,
    gridName,
    apiRef,
    densityFactor,
    createOrder,
    loadPreferences = false
}: IGridOptionsComponent) => {
    const token = localStorage.getItem('backend_jwt') || '';
    const storeDispatch = useDispatch();
    const userData: IUserDataToken = jwt_decode(token);

    const { actionData, selectedItem } = useSelector((store) => store.menu);
    const myRole = useMyRole();

    const canConfigureGridPreference = useMemo(
        () =>
            actionData
                .find((el) => Number(el.menuItem.id) === Number(selectedItem) && Number(el.role.id) === (myRole as number))
                ?.menuItemActions.find((el) => el.name.toLowerCase().includes('grid preferences'))?.enabled,
        [actionData, myRole, selectedItem]
    );

    const [preferenceList, setPreferenceList] = useState<IGridPreference[]>([]);
    const [selectedGridId, setSelectedGridId] = useState('');
    const [appliedGridId, setAppliedGridId] = useState<string | null>(null);
    const [editGridPreference, setEditGridPreference] = useState(false);

    const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(true);

    const modal = useConfirmationModalContext();

    const updateTypeRef = useRef<ChangeListType>('CHANGE_DEFAULT');

    const [getUserPreferences, { loading: userPreferencesLoading, data: userPreferencesData }] = useLazyQuery<ISaasMyGridPreference>(
        PREFERENCES_BACKOFFICE,
        {
            fetchPolicy: 'no-cache',
            variables: { gridName: gridName || 'bills' },
            onCompleted(data) {
                if (!data || !data.SaasMyGridPreferences?.length) {
                    setDefaultGrid(null);
                } else {
                    setPreferenceList(data.SaasMyGridPreferences);
                    handleChangeGridPreferences(data.SaasMyGridPreferences);
                }
            }
        }
    );

    const [createUserPreferences] = useMutation<IRegisterUserPreference, UserGridPreferencesRegisterVariables>(
        MUTATION_CREATE_USER_PREFERENCES,
        {
            update(_cache, { data }) {
                const newPreference = data?.registerUserGridPreferences;

                if (newPreference) {
                    const newPreferenceList = getUpdatedPreferenceList(preferenceList, newPreference, 'NEW');
                    handleChangeGridPreferences(newPreferenceList);
                }
            }
        }
    );

    const [updateUserPreferences] = useMutation<IUpdateGridPreference, UserGridPreferencesUpdateVariables>(
        MUTATION_UPDATE_USER_PREFERENCES,
        {
            update(_cache, { data }) {
                const updatedPreference = data?.updateUserGridPreferences;

                if (updatedPreference) {
                    const newPreferenceList = getUpdatedPreferenceList(preferenceList, updatedPreference, updateTypeRef.current);
                    handleChangeGridPreferences(newPreferenceList);
                }
            }
        }
    );

    const preferenceSelected = useMemo(
        () => preferenceList.find((el) => Number(el.id) === Number(selectedGridId)),
        [preferenceList, selectedGridId]
    );

    const isAdminSelectedGrid = useMemo(() => !!preferenceSelected?.role, [preferenceSelected]);

    const handleChangeGridPreferences = (list: IGridPreference[]) => {
        const gridToApply = getDefaultGrid(list);
        densityFactor.current = gridToApply.gridOptions[0].densityFactor ?? 1;
        localStorage.setItem('grid_density_factor', String(gridToApply.gridOptions[0].densityFactor ?? 1));
        setPreferenceList(list);
        setDefaultGrid(gridToApply);
        setSelectedGridId(String(gridToApply.id));
        setAppliedGridId(String(gridToApply.id));

        updateTypeRef.current = 'CHANGE_DEFAULT';
    };

    const handleChangeDefaultPreference = async (id: string) => {
        const selected = preferenceList.find((el) => Number(el.id) === Number(id));

        if (!selected) return;

        updateTypeRef.current = 'CHANGE_DEFAULT';

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { role, user, ...payload } = selected;

        try {
            if (role) {
                setDefaultGrid(selected);
                setSelectedGridId(String(selected.id));
                setAppliedGridId(String(selected.id));
            } else {
                onLoading(true);
                await updateUserPreferences({
                    variables: { data: { ...payload, id: Number(selected.id), isDefault: true } }
                });
                onLoading(false);
            }

            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Grid preference changed!',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
            onPreferenceSave();
        } catch (err: any) {
            console.log(err);
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }
    };

    const handleSaveNewPreference = async (name?: string) => {
        setIsOpenSaveDialog(false);
        try {
            if (!apiRef?.current) throw Error('apiRef is not defined');
            const actualState = apiRef.current.exportState();
            const gridOptions = generateGridOptionsToSave(actualState, densityFactor.current || 1);
            const savedName = name || '';

            updateTypeRef.current = 'NEW';

            const { data } = await createUserPreferences({
                variables: {
                    data: {
                        isDefault: true,
                        enabled: true,
                        userId: userData.userId,
                        tenantId: 1,
                        gridName,
                        savedName,
                        gridOptions
                    }
                }
            });
            const preference = data?.registerUserGridPreferences as IGridPreference;
            setDefaultGrid(preference);
            setAppliedGridId(String(preference.id));
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes Saved!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            onPreferenceSave();
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
            console.log(err);
        }
    };

    const handleRenamePreference = async (namePreference: string) => {
        try {
            if (!apiRef?.current) throw Error('apiRef is not defined');

            updateTypeRef.current = 'UPDATE_GRID';

            await updateUserPreferences({
                variables: { data: { id: Number(selectedGridId), savedName: namePreference } }
            });
            // const preference = data?.updateUserGridPreferences as IGridPreference;
            // setDefaultGrid(preference);
            // setAppliedGridId(String(preference.id));
            setEditGridPreference(false);
            setIsOpenSaveDialog(false);

            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes Saved!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
            console.log(err);
        }
    };

    const handleDeletePreference = async () => {
        try {
            updateTypeRef.current = 'DELETE';

            await updateUserPreferences({
                variables: { data: { id: Number(selectedGridId), enabled: false } }
            });
            setEditGridPreference(false);
            onPreferenceSave();
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes Saved!',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
            console.log(err);
        }
    };

    const handleUpdatePreference = async () => {
        try {
            if (isAdminSelectedGrid) throw Error('Admin preferences cannot be edited. You should save as new.');
            if (!apiRef?.current) throw Error('apiRef is not defined');
            const actualState = apiRef.current.exportState();
            const gridOptions = generateGridOptionsToSave(actualState, densityFactor.current || 1);

            updateTypeRef.current = 'UPDATE_GRID';

            await updateUserPreferences({
                variables: {
                    data: {
                        id: Number(appliedGridId),
                        gridOptions
                    }
                }
            });
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Changes Saved!',
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
            onPreferenceSave();
        } catch (err: any) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
            console.log(err);
        }
    };

    // Handle user events

    const handleUpdateButton = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isAdminSelectedGrid)
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Admin preferences cannot be edited. You should save as new.',
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        else await handleShowSaveChangesConfirmationDialog();
    };

    const handleSubmitEdit = async (namePreference: string) => {
        await handleShowChangeNameConfirmationDialog(namePreference);
        onPreferenceSave();
    };

    const handleChangePreferenceSelect = (event: SelectChangeEvent) => {
        setSelectedGridId(event.target.value);
        handleChangeDefaultPreference(event.target.value);
    };

    const openEditDialog = () => {
        setIsOpenSaveDialog(true);
        setEditGridPreference(true);
    };

    const openDeleteDialog = () => {
        handleShowDeleteConfirmationDialog();
        setEditGridPreference(false);
    };

    useEffect(() => {
        onLoading(userPreferencesLoading);
    }, [onLoading, userPreferencesLoading]);

    useEffect(() => {
        if (!loadPreferences) return;
        getUserPreferences();
    }, [getUserPreferences, loadPreferences]);

    const handleShowSaveChangesConfirmationDialog = async () => {
        await modal.showConfirmation({
            title: 'Keep Changes?',
            content: `Are you sure you want to save the changes?`,
            loadingText: 'Updating',
            forwardButtonText: 'Yes, Confirm',
            actionButtonText: 'No, Cancel',
            onForward: handleUpdatePreference,
            onAction: null,
            showCheckbox: true,
            actionType: 'edit',
            sectionTitle: 'grid_preferences',
            recordType: gridName.toLowerCase().replaceAll(' ', '_')
        });
    };

    const handleShowChangeNameConfirmationDialog = async (namePreference: string) => {
        await modal.showConfirmation({
            title: 'Keep Changes?',
            content: `Are you sure you want to save the changes?`,
            loadingText: 'Updating',
            forwardButtonText: 'Yes, Confirm',
            actionButtonText: 'No, Cancel',
            onForward: async () => {
                handleRenamePreference(namePreference);
            },
            onAction: null,
            showCheckbox: true,
            actionType: 'edit',
            sectionTitle: 'grid_preferences',
            recordType: gridName.toLowerCase().replaceAll(' ', '_')
        });
    };

    const handleShowDeleteConfirmationDialog = async () => {
        await modal.showConfirmation({
            title: 'Keep Changes?',
            content: `Are you sure you want to delete this grid preference?`,
            loadingText: 'Updating',
            forwardButtonText: 'Yes, Confirm',
            actionButtonText: 'No, Cancel',
            onForward: handleDeletePreference,
            onAction: null,
            showCheckbox: false,
            actionType: 'delete',
            sectionTitle: 'grid_preferences',
            recordType: gridName.toLowerCase().replaceAll(' ', '_')
        });
    };

    return (
        <Box data-testid="grid-options" sx={{ padding: '0.5rem 0.4rem', paddingBottom: 0, display: 'flex' }}>
            <Box
                sx={(theme) => ({
                    border: `1px solid #D9D9D9`,
                    borderRadius: '30px',
                    backgroundColor: theme.palette.primary[300],
                    height: '40px',
                    display: 'flex'
                })}
            >
                <PreferenceSelect
                    value={selectedGridId}
                    disabled={!loadPreferences}
                    onChange={handleChangePreferenceSelect}
                    preferenceList={preferenceList || []}
                    isAdminSelectedGrid={isAdminSelectedGrid}
                    userPreferencesLoading={userPreferencesLoading}
                    userPreferencesData={userPreferencesData}
                    canConfigureGridPreference={canConfigureGridPreference ?? false}
                    openEditDialog={openEditDialog}
                    openDeleteDialog={openDeleteDialog}
                />
                {showSaveChanges && (
                    <>
                        {!isAdminSelectedGrid && canConfigureGridPreference && (
                            <Tooltip title="Save">
                                <IconButton onClick={handleUpdateButton} size="large">
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {canConfigureGridPreference && (
                            <Tooltip title="Save as New">
                                <IconButton onClick={() => setIsOpenSaveDialog(true)} size="large">
                                    <SaveNewIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}
            </Box>
            <Box sx={{ margin: '0 0.5rem' }}>
                {!showSearchInput && (
                    <Tooltip title="Open Searchbar">
                        <IconButton
                            onClick={() => setShowSearchInput(true)}
                            size="large"
                            sx={(theme) => ({ backgroundColor: theme.palette.primary[300] })}
                        >
                            <Search sx={(theme) => ({ color: theme.palette.primary[400] })} />
                        </IconButton>
                    </Tooltip>
                )}
                {showSearchInput && (
                    <SearchBox
                        value={inputValue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInputValue(e.target.value as string)}
                        onClear={() => onChangeInputValue('')}
                    />
                )}
            </Box>
            <Box sx={{ margin: '0 0.5rem', marginLeft: 'auto' }}>
                <Tooltip title="New Record">
                    <IconButton
                        onClick={createOrder}
                        size="large"
                        sx={(theme) => ({
                            '&:hover': { color: theme.palette.secondary.main, backgroundColor: theme.palette.secondary.light }
                        })}
                    >
                        <AddCircleIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <SaveNameDialog
                open={isOpenSaveDialog}
                handleClose={() => setIsOpenSaveDialog(false)}
                handleSubmit={!editGridPreference ? handleSaveNewPreference : handleSubmitEdit}
                isEdit={editGridPreference}
                defaultValue={editGridPreference && preferenceSelected ? preferenceSelected.savedName : ''}
            />
        </Box>
    );
};
