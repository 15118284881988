import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, Button, Dialog, Grid, ListItem, ListItemAvatar, ListItemText, Slide, styled, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { AuditLog } from '../types';
import { getActionIcon } from '../PropertiesPanel/LogReportPanel/utils';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAuditFormattedValue } from '../utils';

export type FullHistoryDialogProps = {
    open: boolean;
    onClose: () => void;
    items?: AuditLog[];
    onFetchMore: () => void;
    total: number;
};

export const FullHistoryDialog = ({ open, onClose, items, onFetchMore, total }: FullHistoryDialogProps) => (
    <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        TransitionComponent={Transition}
        PaperProps={{ sx: { pt: 0 } }}
        style={{ marginTop: 130 }}
    >
        {open && (
            <Grid container id="full-history-list" sx={{ height: '100%', overflowY: 'auto', '& > div': { width: '100%' } }}>
                <InfiniteScroll
                    dataLength={items?.length || 25}
                    next={onFetchMore}
                    hasMore={items?.length !== total}
                    loader={
                        <Typography align="center" variant="caption" sx={{ display: 'block', width: '100%' }}>
                            Loading
                        </Typography>
                    }
                    endMessage={
                        <Typography align="center" variant="caption">
                            No more data to load.
                        </Typography>
                    }
                    style={{ width: '100%' }}
                    scrollableTarget="full-history-list"
                >
                    {items?.map((item, idx) =>
                        item.changes.map((change, changeIdx) => (
                            <StyledRow key={`${change.newValue}-${changeIdx}`} item xs={12} container>
                                <Grid item xs={12} md={3} container>
                                    <ListItem sx={{ justifyContent: { xs: 'flex-start', md: 'center' } }}>
                                        <ListItemAvatar>
                                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                                {getActionIcon(item.action)}
                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText
                                            sx={{ flexGrow: '0' }}
                                            primary={
                                                <Typography textTransform="capitalize">
                                                    Record Data <FormattedMessage id={item.action} />
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography style={{ color: 'black' }}>
                                                    {format(new Date(item.datetime), 'MMM d, y - h:mmaaaa')}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <ListItem style={{ paddingRight: 40 }}>
                                        <ListItemAvatar>
                                            <Avatar style={{ color: '#ffff', backgroundColor: '#B5B5B5' }}>
                                                <AccountCircle />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${item.user.firstName} ${item.user.lastName}`}
                                            secondary={
                                                <span style={{ display: 'block', color: 'black' }}>
                                                    {item.user.email}
                                                    <br />
                                                    <Typography component="span" textTransform="capitalize">
                                                        <FormattedMessage id={item.action} />
                                                    </Typography>
                                                    &nbsp;
                                                    <FormattedMessage id="theFollowingInformationOn" />
                                                    &nbsp;
                                                    <Typography component="span" textTransform="capitalize">
                                                        {change?.what}
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ListItem style={{ paddingRight: 40 }}>
                                        <ListItemText
                                            primary={
                                                <Typography textTransform="capitalize">
                                                    <FormattedMessage id="before" />
                                                </Typography>
                                            }
                                            secondary={
                                                <span style={{ display: 'block', color: 'black' }}>
                                                    <Typography
                                                        component="span"
                                                        dangerouslySetInnerHTML={{ __html: getAuditFormattedValue(change.oldValue) }}
                                                    />
                                                </span>
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ListItem style={{ paddingRight: 40 }}>
                                        <ListItemText
                                            primary={
                                                <Typography textTransform="capitalize">
                                                    <FormattedMessage id="now" />
                                                </Typography>
                                            }
                                            secondary={
                                                <span style={{ display: 'block', color: 'black' }}>
                                                    <Typography
                                                        component="span"
                                                        dangerouslySetInnerHTML={{ __html: getAuditFormattedValue(change.newValue) }}
                                                    />
                                                </span>
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                            </StyledRow>
                        ))
                    )}
                </InfiniteScroll>
            </Grid>
        )}
        <Button onClick={onClose} sx={{ position: 'absolute', top: 10, left: 10 }}>
            <ArrowForwardIcon color="secondary" />
        </Button>
    </Dialog>
);

const StyledRow = styled(Grid)(({ theme }) => ({
    padding: '40px 30px',
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const Transition = forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>
    ) => <Slide direction="left" ref={ref} {...props} />
);

export default FullHistoryDialog;
