import { useLazyQuery } from '@apollo/client';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material';
import { Box, Checkbox, Chip, FormControl, FormGroup, Grid, Link, List, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useSelector } from 'store';
import { QUERY_FIND_LIST_VALUES } from 'graphql/queries/lists';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { IRecordField, IRecordFieldsList } from 'ui-component/records/types';
import { getTimeValidator } from 'ui-component/records/utils';
import { getDateWithoutTZ } from 'ui-component/records/utils/dateHelpers';
import { isJsonString } from 'utils/stringHelpers';

export interface ViewPanelProps {
    fields: Record<keyof IRecordFieldsList, string>;
    headerData: IRecordFieldsList | null;
    loading: boolean;
    handleOpenViewObjectPanel: (field: IRecordField, fieldName: string, editable: boolean) => void;
}

const specialRenderFieldTypes = ['checkbox', 'multiselect', 'multiselect-checkbox', 'url', 'object'];

const ViewPanel = ({ fields, headerData, loading, handleOpenViewObjectPanel }: ViewPanelProps) => {
    const themeSys = useTheme();
    const [allFields, setAllFields] = useState(fields);
    const [loadingDropdownFields, setLoadingDropdownFields] = useState(true);
    const [multiselectCheckboxOptions, setMultiselectCheckboxOptions] = useState<any[]>([]);
    const [findListValue] = useLazyQuery<{ findListValues: any[] }>(QUERY_FIND_LIST_VALUES);
    const { drawerOpen } = useSelector((state) => state.menu);

    const getListValue = useCallback(
        async (keyList: string[]) => {
            setLoadingDropdownFields(true);
            for await (const key of keyList) {
                const id = fields[key as keyof IRecordFieldsList];
                if (id && Array.isArray(JSON.parse(id))) {
                    const idList = JSON.parse(id);
                    const listValue: any[] = [];
                    for await (const filter of idList) {
                        const { data } = await findListValue({ variables: { data: { ids: [Number(filter)] } } });
                        listValue.push(data?.findListValues[0]);
                    }
                    setAllFields((prev) => ({
                        ...prev,
                        [key]: JSON.stringify(listValue.map((item) => item?.userValue?.name || item?.value))
                    }));
                } else {
                    const { data } = await findListValue({ variables: { data: { ids: [Number(id)] } } });
                    const listValue = data?.findListValues.find((val) => val.id === id);

                    setAllFields((prev) => ({ ...prev, [key]: listValue?.userValue?.name || listValue?.value }));
                }
            }
            // Multiselectcheckbox
            const multiselectCheckboxListIdsRaw: number[] = [];
            Object.keys(fields)
                .filter((key) => headerData?.[key as keyof IRecordFieldsList]?.dataType === 'multiselect-checkbox')
                .forEach((key) => {
                    if (!multiselectCheckboxListIdsRaw.includes(Number(headerData?.[key]?.listType?.id)))
                        multiselectCheckboxListIdsRaw.push(Number(headerData?.[key]?.listType?.id));
                });
            const { data: multiselectCheckboxOptionsData } = await findListValue({
                variables: { data: { listIds: multiselectCheckboxListIdsRaw } }
            });
            setMultiselectCheckboxOptions(multiselectCheckboxOptionsData?.findListValues || []);
            setLoadingDropdownFields(false);
        },
        [fields, findListValue, headerData]
    );

    useEffect(() => {
        if (fields) setAllFields(fields);
    }, [fields]);

    useEffect(() => {
        if (headerData && Object.keys(headerData).length && Object.keys(fields).length) {
            const keyList = Object.keys(headerData).filter(
                (key) => headerData[key as keyof IRecordFieldsList]?.listType && fields[key as keyof IRecordFieldsList] !== undefined
            );
            getListValue(keyList);
        }
    }, [getListValue, headerData, fields]);

    // useEffect(() => {
    //     if (multiselectCheckboxListIds.length) getMultiselectCheckboxOptions(multiselectCheckboxListIds);
    // }, [getMultiselectCheckboxOptions, multiselectCheckboxListIds]);

    const mapDefaultValue = (fieldType: string) => (
        <Typography style={{ padding: '10px 0px', fontWeight: 400, fontSize: '13px' }} color="#818181" />
    );

    // TODO: Pass non objects data types to src/ui-component/RecordView/PropertiesPanel/utils.ts
    const mapRealValue = (fieldType: string, fieldValue: string) => {
        switch (fieldType) {
            case 'date':
                return (
                    <Tooltip
                        placement="bottom-start"
                        title={
                            // @ts-ignore
                            new Date(fieldValue).toDateString()
                        }
                    >
                        <Typography
                            style={{
                                maxWidth: '90%',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '5px 10px',
                                fontWeight: 400,
                                fontSize: '13px'
                            }}
                            color="#818181"
                        >
                            {format(getDateWithoutTZ(fieldValue as string), 'MM/dd/yyyy')}
                        </Typography>
                    </Tooltip>
                );

            case 'time':
                return (
                    <Tooltip
                        placement="bottom-start"
                        title={
                            // @ts-ignore
                            new Date(fieldValue).toLocaleTimeString('en-US')
                        }
                    >
                        <Typography
                            style={{
                                maxWidth: '90%',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '5px 10px',
                                fontWeight: 400,
                                fontSize: '13px'
                            }}
                            color="#818181"
                        >
                            {getTimeValidator(fieldValue as string)}
                        </Typography>
                    </Tooltip>
                );
            case 'datetime':
                return (
                    <Tooltip
                        placement="bottom-start"
                        title={
                            // @ts-ignore
                            fieldValue.length ? new Date(fieldValue).toGMTString() : ''
                        }
                    >
                        <Typography
                            style={{
                                maxWidth: '90%',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '5px 10px',
                                fontWeight: 400,
                                fontSize: '13px'
                            }}
                            color="#818181"
                        >
                            {fieldValue.length ? format(new Date(fieldValue as string), 'MM/dd/yyyy hh:mm a') : ''}
                        </Typography>
                    </Tooltip>
                );

            default:
                return (
                    <Typography
                        style={{
                            maxWidth: '90%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            padding: '5px 10px',
                            fontWeight: 400,
                            fontSize: '13px'
                        }}
                        color="#818181"
                    >
                        {fieldValue}
                    </Typography>
                );
        }
    };

    return loading || loadingDropdownFields ? (
        <List sx={{ overflowX: 'hidden' }}>
            {[1, 2, 3, 4, 5].map((el) => (
                <Grid component="li" key={el} container item xs={12} ml={2} mr={1} sx={{ my: '20px' }}>
                    <Grid item xs={5}>
                        <Skeleton height={20} animation="wave" />
                    </Grid>
                    <Grid item xs={8} mt={1}>
                        <Skeleton height={30} animation="wave" />
                    </Grid>
                </Grid>
            ))}
        </List>
    ) : (
        <Grid container maxWidth="md" data-testid="details-list">
            <FormControl style={{ width: '98%', paddingLeft: 2 }} size="small">
                {allFields.recordNumber && (
                    <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 10 }}>
                        <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                            <Grid item xs={drawerOpen ? 12 : 6} paddingLeft="20px">
                                <Typography style={{ fontWeight: 600, fontSize: '13px' }}>Record Number</Typography>
                            </Grid>
                            <Grid xs={drawerOpen ? 12 : 6} sx={{ paddingLeft: !drawerOpen ? 'unset' : '10px' }}>
                                <Typography style={{ padding: '5px 10px', fontWeight: 400, fontSize: '13px' }} color="#818181">
                                    {allFields.recordNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid>
                    {Object.keys(allFields)
                        .filter((key) => key !== 'recordNumber')
                        .map((key) => (
                            <Box key={key}>
                                {!specialRenderFieldTypes.includes(String(headerData?.[key as keyof IRecordFieldsList]?.dataType)) && (
                                    <>
                                        <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 10 }}>
                                            <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                                                <Grid item xs={drawerOpen ? 12 : 6} paddingLeft="20px">
                                                    <Typography style={{ fontWeight: 600, fontSize: '13px', padding: '5px 0px' }}>
                                                        {key.includes(' ') ? key : <FormattedMessage id={key} defaultMessage={key} />}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={drawerOpen ? 12 : 6} sx={{ paddingLeft: !drawerOpen ? 'unset' : '10px' }}>
                                                    {!!allFields[key] && (
                                                        <Typography style={{ fontWeight: 400, fontSize: '13px' }} color="#818181">
                                                            {allFields[key]
                                                                ? mapRealValue(
                                                                      headerData?.[key as keyof IRecordFieldsList]?.dataType || '',
                                                                      allFields[key]
                                                                  )
                                                                : mapDefaultValue(
                                                                      headerData?.[key as keyof IRecordFieldsList]?.dataType || ''
                                                                  )}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {headerData?.[key as keyof IRecordFieldsList]?.dataType === 'checkbox' && (
                                    <FormGroup style={{ padding: '5px 10px' }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={drawerOpen ? 12 : 6} paddingLeft="20px">
                                                <Typography style={{ fontWeight: 600, fontSize: '13px', padding: '5px 0px' }}>
                                                    {key.includes(' ') ? key : <FormattedMessage id={key} defaultMessage={key} />}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={drawerOpen ? 12 : 6}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: drawerOpen ? 'start' : 'center',
                                                    paddingLeft: drawerOpen ? '15px' : 'unset'
                                                }}
                                            >
                                                <Grid xs={6}>
                                                    <Checkbox
                                                        defaultChecked
                                                        checked={!!allFields[key] && allFields[key] === 'true'}
                                                        disabled
                                                        sx={(theme) => ({
                                                            paddingY: '0px',
                                                            paddingLeft: '0px',
                                                            color: theme.palette.primary[400],
                                                            '&.Mui-checked': { color: theme.palette.primary.dark },
                                                            '& .MuiSvgIcon-root': { fontSize: 30 }
                                                        })}
                                                        icon={<CheckBoxOutlineBlankOutlined />}
                                                        checkedIcon={<CheckBoxOutlined />}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                )}
                                {headerData?.[key as keyof IRecordFieldsList]?.dataType === 'url' && (
                                    <>
                                        <Grid item xs={12} style={{ padding: '5px 0px' }}>
                                            <Grid
                                                container
                                                sx={{ display: 'flex', alignItems: 'center', padding: '5px 0px', paddingLeft: '10px' }}
                                            >
                                                <Grid item xs={drawerOpen ? 12 : 6} paddingLeft="20px">
                                                    <Typography style={{ fontWeight: 600, fontSize: '13px' }}>
                                                        {key.includes(' ') ? key : <FormattedMessage id={key} defaultMessage={key} />}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={drawerOpen ? 12 : 6} sx={{ paddingLeft: !drawerOpen ? 'unset' : '10px' }}>
                                                    {!!allFields[key] && (
                                                        <Link
                                                            style={{ textDecoration: 'none' }}
                                                            href={
                                                                allFields[key].includes('http') || allFields[key].includes('https')
                                                                    ? allFields[key]
                                                                    : `https://${allFields[key]}`
                                                            }
                                                            target="_blank"
                                                        >
                                                            <Typography
                                                                style={{
                                                                    maxWidth: '90%',
                                                                    overflowX: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    paddingLeft: 10,
                                                                    padding: 10,
                                                                    fontWeight: 400,
                                                                    fontSize: '13px',
                                                                    textDecoration: 'underline'
                                                                }}
                                                                color="#818181"
                                                            >
                                                                {allFields[key]}
                                                            </Typography>
                                                        </Link>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {headerData?.[key as keyof IRecordFieldsList]?.dataType === 'object' && (
                                    <>
                                        <Grid item xs={12} style={{ padding: '5px 0px' }}>
                                            <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                                                <Grid item xs={drawerOpen ? 12 : 6} paddingLeft="20px">
                                                    <Typography
                                                        style={{
                                                            fontWeight: 600,
                                                            fontSize: '13px',
                                                            padding: '5px 0px'
                                                        }}
                                                    >
                                                        {key.includes(' ') ? key : <FormattedMessage id={key} defaultMessage={key} />}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={drawerOpen ? 12 : 6} sx={{ paddingLeft: !drawerOpen ? 'unset' : '10px' }}>
                                                    {!!allFields[key] && (
                                                        <Typography
                                                            style={{
                                                                paddingLeft: 10,
                                                                padding: 10,
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                textDecoration: 'underline',
                                                                cursor: 'pointer',
                                                                color: themeSys.palette.secondary.main
                                                            }}
                                                            color="#818181"
                                                            onClick={() =>
                                                                handleOpenViewObjectPanel(
                                                                    headerData?.[key as keyof IRecordFieldsList],
                                                                    key,
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            {allFields[key]}
                                                        </Typography>
                                                    )}
                                                    {!allFields[key] && (
                                                        <Typography
                                                            style={{
                                                                paddingLeft: 10,
                                                                padding: 10,
                                                                fontWeight: 400,
                                                                fontSize: '13px',
                                                                textDecoration: 'underline',
                                                                color: themeSys.palette.secondary.main
                                                            }}
                                                            color="#818181"
                                                        >
                                                            No Data
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {headerData?.[key as keyof IRecordFieldsList]?.dataType === 'multiselect-checkbox' &&
                                    multiselectCheckboxOptions && (
                                        <>
                                            <Grid item xs={12} style={{ padding: '5px 0px' }}>
                                                <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                                                    <Grid item xs={12} paddingLeft="20px">
                                                        <Typography style={{ fontWeight: 600, fontSize: '13px' }}>
                                                            {key.includes(' ') ? key : <FormattedMessage id={key} defaultMessage={key} />}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {multiselectCheckboxOptions
                                                .filter(
                                                    (item) =>
                                                        String(headerData?.[key as keyof IRecordFieldsList]?.listType?.id) ===
                                                        String(item.listId.id)
                                                )
                                                .map((item: any, _index: number) => (
                                                    <FormGroup style={{ padding: '5px 10px' }}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={6} sx={{ paddingLeft: '30px' }}>
                                                                <Typography style={{ fontWeight: 400, fontSize: '13px' }}>
                                                                    <FormattedMessage
                                                                        id={item.userValue?.name || item.value}
                                                                        defaultMessage={item.userValue?.name || item.value}
                                                                    />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Grid xs={6}>
                                                                    <Checkbox
                                                                        defaultChecked
                                                                        checked={
                                                                            !!allFields[key] &&
                                                                            isJsonString(allFields[key]) &&
                                                                            Array.isArray(JSON.parse(allFields[key])) &&
                                                                            JSON.parse(allFields[key]).some(
                                                                                (x: string | number) =>
                                                                                    String(x) === String(item.userValue?.name || item.value)
                                                                            )
                                                                        }
                                                                        disabled
                                                                        sx={(theme) => ({
                                                                            paddingY: '0px',
                                                                            paddingLeft: '0px',
                                                                            color: theme.palette.primary[400],
                                                                            '&.Mui-checked': { color: theme.palette.primary.dark },
                                                                            '& .MuiSvgIcon-root': { fontSize: 30 }
                                                                        })}
                                                                        icon={<CheckBoxOutlineBlankOutlined />}
                                                                        checkedIcon={<CheckBoxOutlined />}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </FormGroup>
                                                ))}
                                        </>
                                    )}
                                {headerData?.[key as keyof IRecordFieldsList]?.dataType === 'multiselect' && (
                                    <>
                                        <Grid item xs={12} style={{ padding: '5px 0px' }}>
                                            <Grid container sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                                                <Grid item xs={12} paddingLeft="20px">
                                                    <Typography style={{ fontWeight: 600, fontSize: '13px', padding: '5px 0px' }}>
                                                        {key.includes(' ') ? key : <FormattedMessage id={key} defaultMessage={key} />}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {allFields[key] &&
                                            isJsonString(allFields[key]) &&
                                            Array.isArray(JSON.parse(allFields[key])) &&
                                            JSON.parse(allFields[key]).map((item: string, _index: number) => {
                                                const backgroundColors = [themeSys.palette.secondary.main, '#1845A7', '#005208'];
                                                return (
                                                    <Chip
                                                        sx={(theme) => ({
                                                            margin: '0.5rem',
                                                            backgroundColor: `${backgroundColors[_index % 3]} !important`,
                                                            color: `#fff`,
                                                            marginLeft: '30px'
                                                        })}
                                                        label={item}
                                                        key={`${_index}-${item}`}
                                                    />
                                                );
                                            })}
                                    </>
                                )}
                            </Box>
                        ))}
                </Grid>
            </FormControl>
        </Grid>
    );
};

export default ViewPanel;
