import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox, FormGroup, FormHelperText, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { FormTextField } from 'ui-component/dialogs/FormDialog';
import { Controller, useForm } from 'react-hook-form';
import { PanelFieldSectionInline } from 'views/CreateRecord/components';
import { ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Close, HighlightOffOutlined, Save } from '@mui/icons-material';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import NumberFormat from 'react-number-format';

interface ObjectPanelEditFormProps {
    objectName: string;
    objectProperties: (ObjectProperty & { value: string })[];
    setObjectProperties?: (value: (ObjectProperty & { value: string })[]) => void;
    handleUpdateObjectViewer?: (values: { [key: string]: string }, modalTitle: string) => void;
    handleClosePanelViewer?: (values: { [key: string]: string }, modalTitle: string) => void;
    handleUpdateObjectGrid?: (newProperties: (ObjectProperty & { value: string })[], modalTitle: string) => void;
    handleClosePanelGrid?: (newProperties: (ObjectProperty & { value: string })[], modalTitle: string) => void;
    showTopCloseButton?: boolean;
    isRecordViewer?: boolean;
}

const ObjectPanelEditForm = ({
    objectName,
    objectProperties,
    setObjectProperties,
    handleUpdateObjectViewer,
    handleClosePanelViewer,
    handleUpdateObjectGrid,
    handleClosePanelGrid,
    showTopCloseButton = false,
    isRecordViewer = false
}: ObjectPanelEditFormProps) => {
    const theme = useTheme();
    const labelStyle = { fontSize: '12px', fontWeight: '500', color: '#858585', textOverflow: 'ellipsis', overflow: 'hidden' };
    const [focusedInputs, setFocusedInputs] = React.useState<{ [key: string]: boolean }>({});
    const defaultValues: { [key: string]: string } = {};
    objectProperties.forEach((item) => {
        defaultValues[item.name] = item.value;
    });

    const {
        control,
        formState: { errors },
        getValues,
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const handleSaveChanges = () => {
        const values = getValues();
        if (isRecordViewer) {
            handleUpdateObjectViewer?.(values, 'Do you want to save this changes?');
        } else {
            const newObjectProperties = objectProperties.map((item: ObjectProperty & { value: string }) => ({
                ...item,
                value: values[item.name]
            }));
            setObjectProperties?.(newObjectProperties);
            handleUpdateObjectGrid?.(newObjectProperties, 'Do you want to save this changes?');
        }
    };

    const handleDiscardChanges = () => {
        const values = getValues();
        if (isRecordViewer) {
            handleClosePanelViewer?.(values, 'Are you sure you want to discard your changes?');
        } else {
            const newObjectProperties = objectProperties.map((item: ObjectProperty & { value: string }) => ({
                ...item,
                value: values[item.name]
            }));
            setObjectProperties?.(newObjectProperties);
            handleClosePanelGrid?.(newObjectProperties, 'Are you sure you want to discard your changes?');
        }
    };

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} position="relative">
            {!isRecordViewer && (
                <Box sx={{ padding: '12px', paddingBottom: '0', paddingLeft: '0', height: '10%' }}>
                    <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={10}>
                            <Typography
                                gutterBottom
                                sx={{ fontWeight: '500', fontSize: '16px', color: theme.palette?.secondary.main, marginBottom: 0 }}
                            >
                                {objectName}
                            </Typography>
                        </Grid>
                        {showTopCloseButton && (
                            <Grid item xs={2}>
                                <IconButton onClick={handleDiscardChanges}>
                                    <HighlightOffOutlined fontSize="medium" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
            <Box
                sx={{
                    paddingX: '12px',
                    paddingTop: '0',
                    paddingBottom: '2rem',
                    height: isRecordViewer ? 'calc(100% - 64px)' : '80%',
                    overflow: 'auto'
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSaveChanges();
                    }}
                >
                    {objectProperties.map((item) => (
                        <Box key={item.name}>
                            {(item.dataType === 'string' || item.dataType === 'url') && (
                                <PanelFieldSectionInline
                                    labelStyle={labelStyle}
                                    title={`${item.name}`}
                                    isFocused={focusedInputs[item.name]}
                                >
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue="null"
                                        render={({ field }) => (
                                            <>
                                                <FormTextField
                                                    variant="outlined"
                                                    error={!!errors[item.name]}
                                                    helperText={errors[item.name]?.message}
                                                    focused
                                                    fullWidth
                                                    sx={{
                                                        backgroundColor: '#fafafa !important',
                                                        '& fieldset': {
                                                            border: 'none !important'
                                                        },
                                                        '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                                                            backgroundColor: `${theme.palette?.secondary.light} !important`,
                                                            color: `${theme.palette?.secondary.main} !important`,
                                                            '&::placeholder': { color: 'transparent !important' },
                                                            '& svg': {
                                                                color: `${theme.palette?.secondary.main} !important`,
                                                                opacity: 0.5
                                                            }
                                                        }
                                                    }}
                                                    {...field}
                                                    onFocus={() => {
                                                        setFocusedInputs((prev) => ({ ...prev, [item.name]: true }));
                                                    }}
                                                    onBlur={() => {
                                                        setFocusedInputs((prev) => ({ ...prev, [item.name]: false }));
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}

                            {item.dataType === 'number' && (
                                <PanelFieldSectionInline labelStyle={labelStyle} title={item.name}>
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <NumberFormat
                                                customInput={TextField}
                                                fixedDecimalScale={false}
                                                thousandSeparator=""
                                                decimalScale={0}
                                                variant="outlined"
                                                error={!!errors[item.name]}
                                                helperText={errors[item.name]?.message}
                                                fullWidth
                                                {...field}
                                                onFocus={() => {
                                                    setFocusedInputs((prev) => ({ ...prev, [item.name]: true }));
                                                }}
                                                onBlur={() => {
                                                    setFocusedInputs((prev) => ({ ...prev, [item.name]: false }));
                                                }}
                                                sx={{
                                                    '& fieldset': {
                                                        border: 'none !important'
                                                    },
                                                    '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                                                        backgroundColor: `${theme.palette?.secondary.light} !important`,
                                                        color: `${theme.palette?.secondary.main} !important`,
                                                        '&::placeholder': { color: 'transparent !important' },
                                                        '& svg': {
                                                            color: `${theme.palette?.secondary.main} !important`,
                                                            opacity: 0.5
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}

                            {item.dataType === 'date' && (
                                <PanelFieldSectionInline
                                    labelStyle={labelStyle}
                                    title={`${item.name}`}
                                    isFocused={focusedInputs[item.name]}
                                >
                                    <Controller
                                        name={`${item.name}`}
                                        control={control}
                                        defaultValue={undefined}
                                        render={({ field: { onChange, value: datePicker, ref } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    onChange={onChange}
                                                    inputRef={ref}
                                                    value={datePicker}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            onFocus={() => {
                                                                setFocusedInputs((prev) => ({ ...prev, [item.name]: true }));
                                                            }}
                                                            onBlur={() => {
                                                                setFocusedInputs((prev) => ({ ...prev, [item.name]: false }));
                                                            }}
                                                            sx={{
                                                                '& fieldset': {
                                                                    border: 'none !important'
                                                                },
                                                                '& :focus-visible, :focus, :active, .Mui-focused': {
                                                                    backgroundColor: `${theme.palette?.secondary.light} !important`,
                                                                    color: `${theme.palette?.secondary.main} !important`,
                                                                    '&::placeholder': { color: 'transparent !important' },
                                                                    '& svg': {
                                                                        color: `${theme.palette?.secondary.main} !important`,
                                                                        opacity: 0.5
                                                                    }
                                                                }
                                                            }}
                                                            fullWidth
                                                            {...params}
                                                            error={!!errors[item.name]}
                                                            helperText={errors[item.name]?.message}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}

                            {item.dataType === 'datetime' && (
                                <PanelFieldSectionInline
                                    labelStyle={labelStyle}
                                    title={`${item.name}`}
                                    isFocused={focusedInputs[item.name]}
                                >
                                    <Controller
                                        name={`${item.name}`}
                                        control={control}
                                        defaultValue={undefined}
                                        render={({ field: { onChange, value: datePicker, ref } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    onChange={onChange}
                                                    inputRef={ref}
                                                    value={datePicker}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            onFocus={() => {
                                                                setFocusedInputs((prev) => ({ ...prev, [item.name]: true }));
                                                            }}
                                                            onBlur={() => {
                                                                setFocusedInputs((prev) => ({ ...prev, [item.name]: false }));
                                                            }}
                                                            sx={{
                                                                '& fieldset': {
                                                                    border: 'none !important'
                                                                },
                                                                '& :focus-visible, :focus, :active, .Mui-focused': {
                                                                    backgroundColor: `${theme.palette?.secondary.light} !important`,
                                                                    color: `${theme.palette?.secondary.main} !important`,
                                                                    '&::placeholder': { color: 'transparent !important' },
                                                                    '& svg': {
                                                                        color: `${theme.palette?.secondary.main} !important`,
                                                                        opacity: 0.5
                                                                    }
                                                                }
                                                            }}
                                                            fullWidth
                                                            {...params}
                                                            error={!!errors[item.name]}
                                                            helperText={errors[item.name]?.message}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </PanelFieldSectionInline>
                            )}

                            {item.dataType === 'checkbox' && (
                                <Grid item xs={12} sx={{ py: '10px' }}>
                                    <Controller
                                        name={item.name}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <>
                                                <FormGroup style={{ padding: 10 }}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={4}>
                                                            <Typography style={{ fontWeight: 600, fontSize: '13px' }}>
                                                                {item.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Grid xs={6} sx={{ textAlign: 'center' }}>
                                                                <Checkbox
                                                                    defaultChecked
                                                                    checked={!!field.value && field.value === 'true'}
                                                                    onChange={(event: any) => {
                                                                        setValue(item.name, String(event.target.checked));
                                                                    }}
                                                                    sx={{
                                                                        padding: 0,
                                                                        color: theme.palette?.primary[400],
                                                                        '&.Mui-checked': { color: theme.palette?.primary.dark },
                                                                        '& .MuiSvgIcon-root': { fontSize: 30 }
                                                                    }}
                                                                    icon={<CheckBoxOutlineBlankOutlined />}
                                                                    checkedIcon={<CheckBoxOutlined />}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </FormGroup>
                                                {errors[item.name] && (
                                                    <FormHelperText error sx={{ paddingLeft: '1rem' }}>
                                                        {errors[item.name]?.message}
                                                    </FormHelperText>
                                                )}
                                            </>
                                        )}
                                    />
                                </Grid>
                            )}
                        </Box>
                    ))}
                </form>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    padding: '12px',
                    background: 'white',
                    width: '100%',
                    borderTop: `1px solid #D9D9D9`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end'
                }}
            >
                <Box sx={{ paddingRight: '1rem' }}>
                    <Tooltip title="Discard Changes">
                        <IconButton
                            aria-label="cancel"
                            sx={{
                                backgroundColor: theme.palette?.primary[200],
                                '&:hover': { backgroundColor: theme.palette?.primary[200] }
                            }}
                            onClick={() => handleDiscardChanges()}
                        >
                            <Close sx={{ color: '#666' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ paddingRight: isRecordViewer ? '0' : '1rem' }}>
                    <Tooltip title="Save Changes">
                        <IconButton
                            aria-label="save"
                            sx={{
                                backgroundColor: theme.palette?.secondary.main,
                                '&:hover': { backgroundColor: theme.palette?.secondary.main }
                            }}
                            onClick={() => handleSaveChanges()}
                        >
                            <Save sx={{ color: '#FFF' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};

export default ObjectPanelEditForm;
