/* eslint-disable no-nested-ternary */
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_FIND_LINE_ITEMS } from 'graphql/queries/lineItems';
import { Button, Fade, Grid, IconButton, Paper, Stack } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { GridColDef, GridRowModes, GridRowModesModel, useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
    CreateLineItemsVariables,
    FindLineItemVariables,
    ICreateLineItems,
    IFindLineItems,
    ILineItemHeader,
    IUpdateLineItems,
    UpdateLineItemsVariables
} from './types';
import { MUTATION_REGISTER_LINE_ITEM, MUTATION_UPDATE_LINE_ITEM } from 'graphql/mutations/lineItems';
import { generateLineItemRows, getValueFromLineItemsData } from './utils';
import GridWithInlineEdit, { GridWithInlineEditRef } from 'ui-component/grids/GridWithInlineEdit';
import { StripedDataGrid } from 'views/backoffice/CustomLists/components';
import { getRenderCell, getRenderEditCellByType, getValueFormatter } from '../utils';
import { IRecordField } from '../types';

export interface CustomDetailPanelProps {
    isOpen: boolean;
    recordId: number;
    recordTypeId: number;
    headers: ILineItemHeader;
    onActiveEditing: any;
}

// TODO: use LineItemsGrid here
const CustomDetailPanel = ({ isOpen, recordId, recordTypeId, headers, onActiveEditing }: CustomDetailPanelProps) => {
    const gridRef = useRef<GridWithInlineEditRef | null>(null);
    const [editModel, setEditModel] = useState<GridRowModesModel>({});
    const [newRows, setNewRows] = useState<Record<string, any>[]>([]);
    const apiRef = useGridApiContext();
    const [panelWidth, setPanelWidth] = useState(apiRef.current.rootElementRef?.current?.clientWidth || 0);

    const [getLineItems, { data: lineItemsData, loading: loadingList }] = useLazyQuery<IFindLineItems, FindLineItemVariables>(
        QUERY_FIND_LINE_ITEMS,
        {
            onCompleted(data) {
                if (data.findLineItems.length) {
                    const rows = generateLineItemRows(headers.lineItemsByTypeFileds, data.findLineItems);
                    setNewRows(rows);
                }
            }
        }
    );
    const [createLineItem, { loading: loadingCreation }] = useMutation<ICreateLineItems, CreateLineItemsVariables>(
        MUTATION_REGISTER_LINE_ITEM,
        {
            update(cache, { data }) {
                const created = data?.createLineItems;
                const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    }
                });
                if (!allFields || !created) return;

                cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    },
                    data: {
                        findLineItems: [...allFields.findLineItems, created]
                    }
                });
            }
        }
    );
    const [updateLineItem, { loading: loadingUpdate }] = useMutation<IUpdateLineItems, UpdateLineItemsVariables>(
        MUTATION_UPDATE_LINE_ITEM,
        {
            update(cache, { data }) {
                const updated = data?.updateLineItems;
                const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    }
                });
                if (!allFields || !updated) return;

                cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    },
                    data: {
                        findLineItems: allFields.findLineItems.map((el) => (el.id === updated.id ? updated : el))
                    }
                });
            }
        }
    );

    const headerList: GridColDef[] = useMemo(() => {
        if (!headers) return [];
        const sortedArr = [...headers.lineItemsByTypeFileds].sort((a, b) => a.order - b.order);

        return sortedArr.map(
            ({ recordAdditionalFields, order }) =>
                ({
                    field: `${recordAdditionalFields.name}-${order}`,
                    headerName: recordAdditionalFields.name,
                    editable: true,
                    valueFormatter: getValueFormatter(recordAdditionalFields.name, recordAdditionalFields.dataType),
                    renderCell: getRenderCell(
                        recordAdditionalFields.name,
                        undefined,
                        undefined,
                        recordAdditionalFields as unknown as IRecordField
                    ),
                    renderEditCell: getRenderEditCellByType(recordAdditionalFields.dataType, recordAdditionalFields.listType?.id)
                } as GridColDef)
        );
    }, [headers]);

    const handleClickSave = () => {};

    const handleClickCancel = () => setEditModel({});

    const handleClickAdd = () => {
        gridRef.current?.handleAddClick();
    };

    const handleProcessUpdate = async (newOne: any) => {
        try {
            const items = Object.keys(newOne)
                .filter((key) => !['id', 'order'].includes(key))
                .filter((key) => {
                    const newValue = newOne[key];
                    const oldData = getValueFromLineItemsData(newOne.id, lineItemsData?.findLineItems, headers.lineItemsByTypeFileds, key);

                    return !oldData || newValue !== oldData.value;
                })
                .map((key) => {
                    const [fieldNameFromUpdated, fieldOrder] = key.split('-');
                    const idFromHeader = Number(
                        headers?.lineItemsByTypeFileds.find(
                            (el) => el.recordAdditionalFields.name === fieldNameFromUpdated && el.order === Number(fieldOrder)
                        )?.id
                    );

                    const oldData = getValueFromLineItemsData(newOne.id, lineItemsData?.findLineItems, headers.lineItemsByTypeFileds, key);

                    const id = Number(oldData?.id);

                    return id
                        ? updateLineItem({
                              variables: {
                                  data: {
                                      id,
                                      index: Number(newOne.id),
                                      lineItemsByTypeFiledsId: idFromHeader,
                                      recordHeaderId: recordId,
                                      value: newOne[key]
                                  }
                              }
                          })
                        : newOne[key]
                        ? createLineItem({
                              variables: {
                                  data: {
                                      index: Number(newOne.id),
                                      lineItemsByTypeFiledsId: idFromHeader,
                                      recordHeaderId: recordId,
                                      value: newOne[key]
                                  }
                              }
                          })
                        : null;
                });

            await Promise.all(items.filter((el) => el !== null));
            return newOne;
        } catch (error) {
            console.log('error changing values', error);
        }
        return null;
    };

    const handleProcessCreate = async (newOne: any) => {
        try {
            const items = Object.keys(newOne)
                .filter((key) => !['id', 'autogenerate', 'order'].includes(key) && newOne[key])
                .map((key) => {
                    const [fieldNameFromUpdated, fieldOrder] = key.split('-');
                    const idFromHeader = Number(
                        headers?.lineItemsByTypeFileds.find(
                            (el) => el.recordAdditionalFields.name === fieldNameFromUpdated && el.order === Number(fieldOrder)
                        )?.id
                    );

                    return createLineItem({
                        variables: {
                            data: {
                                index: Number(newRows.length) + 1,
                                lineItemsByTypeFiledsId: idFromHeader,
                                recordHeaderId: recordId,
                                value: newOne[key]
                            }
                        }
                    });
                });

            await Promise.all(items);
            return { ...newOne, id: Number(newRows.length) + 1 };
        } catch (error) {
            console.log('error creating new line item', error);
            return null;
        }
        // console.log('responses created', res);
    };

    const isEditing = useMemo(() => Object.values(editModel).some((value) => value.mode === GridRowModes.Edit), [editModel]);

    useEffect(() => {
        onActiveEditing(isEditing);
    }, [isEditing, onActiveEditing]);

    useEffect(() => {
        if (isOpen) {
            getLineItems({
                variables: {
                    data: {
                        recordHeadersIds: [recordId]
                    }
                }
            });
        }
    }, [getLineItems, isOpen, recordId]);

    // To resize the panel
    useEffect(() => {
        let observer: null | ResizeObserver = null;

        if (apiRef?.current?.rootElementRef?.current) {
            observer = new ResizeObserver((e) => {
                setPanelWidth(e[0].target.clientWidth);
            });

            observer.observe(apiRef.current.rootElementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [apiRef]);

    return (
        <Stack sx={{ display: 'flex', height: '100%', maxWidth: panelWidth || 'inherit' }}>
            <Paper elevation={1} component={Grid} container sx={{ height: '400px', m: '20px', p: '20px', flexGrow: 1 }}>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={10} sx={{ height: '100%' }}>
                        <GridWithInlineEdit
                            ref={gridRef}
                            GridComponent={StripedDataGrid}
                            density="compact"
                            onCreate={handleProcessCreate}
                            onUpdate={handleProcessUpdate}
                            loading={loadingCreation || loadingUpdate || loadingList}
                            columns={headerList}
                            fieldToFocus={headerList[0]?.field || ''}
                            initialRows={newRows}
                            disabledReordering
                            disabledCheckboxSelection
                            shouldEdit
                            autosizeColumns
                            pinnedActions
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ maxWidth: '100px !important' }}>
                        <IconButton onClick={handleClickAdd} sx={{ mb: 1 }} color="secondary" aria-label="add new row">
                            <AddCircleOutline />
                        </IconButton>
                        <Fade in={isEditing}>
                            <Button onClick={handleClickSave} sx={{ mb: 1 }} variant="contained" color="secondary" fullWidth>
                                Save
                            </Button>
                        </Fade>
                        <Fade in={isEditing}>
                            <Button onClick={handleClickCancel} variant="contained" color="secondary" fullWidth>
                                Cancel
                            </Button>
                        </Fade>
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
};

export default CustomDetailPanel;
