import { gql } from '@apollo/client';

export const QUERY_GET_MY_ITEM_ACTIONS = gql`
    query SaasMyRoleMenuItemActions {
        SaasMyRoleMenuItemActions {
            id
            menuItem {
                id
                name
            }
            role {
                id
                name
            }
            menuItemActions {
                id
                name
                enabled
            }
        }
    }
`;
