import { gql } from '@apollo/client';

export const QUERY_GET_USERS = gql`
    query GetSaasUsers {
        getSaasUsers {
            id
            email
            name
            firstName
            lastName
            enabled
            updatedAt
            createdAt
            BackOfficeAccess
            SaasAccess
            fireBaseId
            phone
            roles {
                id
                tenant {
                    id
                }
                role {
                    id
                    name
                }
            }
            validated
            tenants {
                id
                name
            }
        }
    }
`;

export const QUERY_FIND_USERS = gql`
    query FindSaasUser($data: UserFindInput!) {
        findSaasUser(data: $data) {
            id
            email
            name
            firstName
            lastName
            enabled
            updatedAt
            createdAt
            BackOfficeAccess
            SaasAccess
            fireBaseId
            validated
            tenants {
                id
                name
            }
        }
    }
`;

export const QUERY_GET_USER = gql`
    query UserFind($filters: UserFindInput!) {
        UserFind(data: $filters) {
            id
            email
            name
            lastName
            firstName
            enabled
            roles {
                id
                tenant {
                    id
                }
                role {
                    id
                    name
                }
            }
            createdAt
            updatedAt
            tenants {
                id
                name
            }
        }
    }
`;

export const QUERY_FIND_USER = gql`
    query FindUser($filters: UserFindInput!) {
        findUser(data: $filters) {
            id
            email
            name
            lastName
            firstName
            enabled
            roles {
                id
                tenant {
                    id
                }
                role {
                    id
                    name
                }
            }
            createdAt
            updatedAt
            tenants {
                id
                name
            }
        }
    }
`;

export const QUERY_FIND_USER_ROLE = gql`
    query Query($data: UserRoleByTenantsFindInput!) {
        findUserRoleByTenants(data: $data) {
            id
            user {
                id
            }
            tenant {
                id
                name
            }
            role {
                id
                name
            }
            enabled
            createdAt
            updatedAt
        }
    }
`;
