import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_LIST_VALUES } from 'graphql/queries/lists';
import { useEffect, useMemo } from 'react';
import { isJsonString } from 'utils/stringHelpers';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';

export type MultiselectGridRenderProps = {
    value: string;
    listValues?: ListValue[];
    listId?: number;
};

export const MultiselectGridRender = ({ listId, value, listValues }: MultiselectGridRenderProps) => {
    const [getListValues, { data: listValuesData }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_FIND_LIST_VALUES, {
        variables: { data: { listId: Number(listId) } }
    });

    const values = useMemo(() => {
        if (listValues) return listValues;
        if (listValuesData) return listValuesData.findListValues || [];
        return [] as ListValue[];
    }, [listValues, listValuesData]);

    useEffect(() => {
        if (listId) getListValues();
    }, [listId, getListValues]);

    return (
        <>
            {value && isJsonString(value) && value !== '0' && (
                <>
                    {JSON.parse(value).map((item: any, _index: number) => {
                        const selectedOption = values.find((listItem) => Number(item) === Number(listItem.id));
                        return (
                            <span key={_index}>
                                {`${_index > 0 ? `, ` : ``}${selectedOption?.userValue?.name || selectedOption?.value || ''}`}
                            </span>
                        );
                    })}
                </>
            )}
        </>
    );
};
