import { formatInTimeZone } from 'date-fns-tz';
/* eslint-disable no-nested-ternary */
import { IRecordField, IRecordFieldsList, TargetTenant } from 'ui-component/records/types';
import { IFormatDataValues, IProblemCode, IRecordFile, IRecordStatus } from './types';
import { IUser } from 'views/backoffice/users/types';
import { format, isValid } from 'date-fns';

export const getDocumentFileUrl = (recordFile: IRecordFile) => {
    try {
        const buffer = Buffer.from(recordFile.fileEncoded, 'base64');
        const blob = new Blob([buffer], { type: 'application/pdf' });

        return URL.createObjectURL(blob);
    } catch (err) {
        console.log(err);
        return '';
    }
};

export const getFormattedValue = (value: any, fieldData?: Pick<IRecordField, 'id' | 'dataType'>) => {
    if (!fieldData) return value;
    const { dataType, id } = fieldData;
    const isBaseField = Number(id) === 0;
    if (dataType?.toLowerCase() === 'date') {
        if (value === '') return value;
        return Date.parse(value) ? format(new Date(value as string), 'MM/dd/yyyy') : undefined;
    }
    if (dataType?.toLowerCase() === 'datetime') {
        if (value === '') return value;
        return Date.parse(value) ? formatInTimeZone(new Date(value as string), 'Etc/GMT', 'yyyy-MM-dd HH:mmXXX') : undefined;
    }
    if (dataType?.toLowerCase() === 'time') {
        if (value === '') return value;
        return value && Date.parse(value as string) ? new Date(value).toTimeString().slice(0, 5) : undefined;
    }
    if (dataType?.toLowerCase() === 'dropdown') {
        if (isBaseField) return Number(value);
        return value || undefined;
    }
    if (dataType?.toLowerCase() === 'object') {
        return '';
    }
    if (dataType?.toLowerCase() === 'number' && isBaseField) return Number(value);

    return value;
};

export const getDataToUpdate = (
    data: Record<string, any>,
    baseFieldHeaders: Partial<IRecordFieldsList> | null,
    aditionalFieldHeader: Partial<IRecordFieldsList> | null,
    aditionalFieldRawValues: { id: string; tag: string; value: any }[]
) => {
    if (!aditionalFieldHeader || !baseFieldHeaders) return { baseData: data, additionalFields: [] } as IFormatDataValues;
    const baseData = Object.fromEntries(
        Object.entries(data)
            .filter(([key]) => !Object.keys(aditionalFieldHeader).includes(key))
            .map(([key, value]) => {
                const newValue = getFormattedValue(value, baseFieldHeaders[key]);
                return [key, newValue];
            })
    );

    const additionalFields = Object.keys(data)
        .filter((key) => Object.keys(aditionalFieldHeader).includes(key))
        .map((key) => {
            const aditionalFieldHeaderId = aditionalFieldHeader[key]?.id;
            const selectedTag = aditionalFieldRawValues.find((el) => {
                let [, , fieldId] = el.tag.split(';');
                fieldId = fieldId || el.tag.split(':')[1].split(';')[1];
                return Number(fieldId) === Number(aditionalFieldHeaderId);
            });
            return {
                tag: selectedTag?.tag || `;;${aditionalFieldHeaderId}`,
                value: getFormattedValue(data[key], aditionalFieldHeader[key]) ?? selectedTag?.value ?? '',
                objectValuesByProperty:
                    aditionalFieldHeader[key]?.dataType === 'object' && data[key]
                        ? data[key].filter((el: any) => !!el.value).map((el: any) => ({ propertyId: +el.id, value: el.value }))
                        : undefined
            };
        });

    return { baseData, additionalFields } as IFormatDataValues;
};

export const getDataToCreate = (
    data: Record<string, any>,
    baseFieldHeaders: Partial<IRecordFieldsList> | null,
    aditionalFieldHeader: Partial<IRecordFieldsList> | null
) => {
    if (!aditionalFieldHeader || !baseFieldHeaders) return { baseData: data, additionalFields: [] } as IFormatDataValues;
    const baseData = Object.fromEntries(
        Object.entries(data)
            .filter(([, value]) => !!value)
            .filter(([key]) => !Object.keys(aditionalFieldHeader).includes(key))
            .map(([key, value]) => {
                const newValue = getFormattedValue(value, baseFieldHeaders[key]);
                return [key, newValue];
            })
    );

    const additionalFields = Object.keys(data)
        .filter((key) => !!data[key])
        .filter((key) => Object.keys(aditionalFieldHeader).includes(key))
        .filter((key) => getFormattedValue(data[key], aditionalFieldHeader[key]) !== undefined)
        .map((key) => {
            const aditionalFieldHeaderId = aditionalFieldHeader[key]?.id;
            return {
                tag: `;;${aditionalFieldHeaderId}`,
                value: getFormattedValue(data[key], aditionalFieldHeader[key]),
                objectValuesByProperty:
                    aditionalFieldHeader[key]?.dataType === 'object'
                        ? data[key].filter((el: any) => !!el.value).map((el: any) => ({ propertyId: +el.id, value: el.value }))
                        : undefined
            };
        });

    return { baseData, additionalFields } as IFormatDataValues;
};

export const downloadPdf = (url: string, fileName: string = 'Document') => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const convertArrayBufferToUrl = (arrayBuffer: any) => {
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
};

/**
 * Returns value formatted to show in Audit Log
 *
 * @param value {String | Number | Boolean}
 */
export const getAuditFormattedValue = (value: string | number | boolean = '') => {
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'string' && isValid(value)) return format(new Date(value), 'MMM d, y');
    if (typeof value === 'string') return value.replace(/\\n/g, '<br/>');
    if (value === null) return '';

    return String(value);
};

export const getDropdownOptionList = (
    key:
        | 'recordType'
        | 'statusId'
        | 'createdBy'
        | 'receivedBy'
        | 'approvedBy'
        | 'problemCodeId'
        | 'assignedTo'
        | 'targetTransmissionTenant',
    recordTypeList: any,
    recordStatusList: IRecordStatus[],
    problemCodeList: IProblemCode[],
    usersList: IUser[],
    targetTenantList: TargetTenant[]
) => {
    switch (key) {
        case 'recordType':
            return recordTypeList;
        case 'statusId':
            return recordStatusList;
        case 'problemCodeId':
            return problemCodeList;
        case 'targetTransmissionTenant':
            return targetTenantList.map((el) => el.targetTenantToTransmit);
        case 'assignedTo':
        case 'createdBy':
        case 'receivedBy':
        case 'approvedBy':
            return usersList
                .filter((el: any) => Boolean(el.email))
                .sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
        default:
            return [];
    }
};

export const generateInitialState = (baseFieldHeaders: Partial<IRecordFieldsList>, recordType?: string) => {
    const initialState = Object.keys(baseFieldHeaders)
        .filter((key) => key !== 'id')
        .reduce((acc, key) => ({ ...acc, [key]: '' }), {});

    return { ...initialState, recordType: Number(recordType) || undefined };
};
