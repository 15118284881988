/* eslint-disable react/destructuring-assignment */
import jwtDecode from 'jwt-decode';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Avatar, Box, Grid, Tab, Typography } from '@mui/material';
import { SocketContext } from 'contexts/socket';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IUserDataToken } from 'utils/types';
import { IGetUsers } from 'views/backoffice/users/types';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { NewCommentSection } from './components';
import { useLocation } from 'react-router-dom';

export const CommentsPanel = () => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const recordId = queryParams.get('recordId') || '';
    const tenantId = localStorage.getItem('tenant_id');

    const token = localStorage.getItem('backend_jwt') || '';
    const user: IUserDataToken = jwtDecode(token);
    const socket = useContext(SocketContext);
    const [selectedTab, setSelectedTab] = useState('1');
    const [commentList, setCommentList] = useState<any[]>([
        {
            id: '1',
            name: 'Amanda Anderson',
            tenant: {
                name: 'Lumen'
            },
            content: `The office supply purchasing experience was excellent. 
            Tha products arrived in perfect condition and the ordering process 
            was very easy. I will definetely recommend this store `,
            date: '2021-09-01T00:00:00.000Z'
        }
    ]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: usersData } = useQuery<IGetUsers>(QUERY_GET_USERS);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    const handleSendComment = (message: any) => {
        console.log('sendComment', message);
        socket.emit('createComment', { userId: user.userId, content: message, recordHeaderId: +recordId, tenantId: +(tenantId as string) });
    };

    const handleNewComments = useCallback(
        (payload: { comment: string; name: string }) => {
            console.log('new comment', payload);
            setCommentList((prevState) => [
                {
                    id: commentList.length + 1,
                    name: payload.name,
                    tenant: { name: 'Lumen' },
                    content: payload.comment,
                    date: new Date().toISOString()
                },
                ...prevState
            ]);
        },
        [commentList.length]
    );

    useEffect(() => {
        socket.connect();
        console.log('#### SOCKET', socket.connected);
        // subscribe to socket events
        // socket.on('getComment', (payload: any) => console.log('payload getComment', payload));
        socket.onAny((eve: string, payload: any) => console.log('payload getComments', eve, payload));

        // return () => {
        //     socket.off('getComment', (payload: any) => console.log('payload getComment', payload));
        //     socket.off('getComments', (payload: any) => console.log('payload getComments', payload));
        // };
    }, [handleNewComments, socket]);

    return (
        <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: '-30px' }}>
                <TabList onChange={handleChange}>
                    <Tab label="ALL" value="1" />
                </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0, height: 'calc(100% - 108px)', overflowY: 'auto' }}>
                <NewCommentSection onSendComment={handleSendComment} />

                {/* Comments */}

                <Grid container sx={{ width: '100%', mt: '8px' }}>
                    {!!commentList.length &&
                        commentList.map((comment) => (
                            <Grid item container key={comment.id} spacing={2} xs={12} sx={{ mb: '15px' }}>
                                <Grid item xs={1}>
                                    <Avatar
                                        sx={(theme) => ({
                                            mt: 1,
                                            width: 40,
                                            height: 40,
                                            color: 'white',
                                            bgcolor: theme.palette.secondary.main
                                        })}
                                    >
                                        {comment.name[0]}
                                    </Avatar>
                                </Grid>
                                <Grid item container xs={11}>
                                    <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                                        <Grid container item xs="auto" alignItems="center">
                                            <Typography variant="subtitle1" color="secondary" fontSize="16px">
                                                {comment.name}
                                            </Typography>
                                            <span style={{ margin: '0 20px' }}>
                                                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2" cy="2" r="2" fill="#ED6C02" />
                                                </svg>
                                            </span>
                                            <Typography variant="subtitle1" sx={{ color: 'ED6C02' }} fontSize="16px">
                                                {comment.tenant.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Typography variant="caption" color="secondary">
                                                {format(new Date(comment.date), 'yyyy-MM-dd hh:mm')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" fontSize="14px" color="secondary">
                                            {comment.content}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </TabPanel>
        </TabContext>
    );
};
