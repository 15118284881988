import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_TARGET_TENANTS } from 'graphql/queries/bills';
import { QUERY_GET_PROBLEM_CODE } from 'graphql/queries/problemCode';
import { QUERY_FIND_RECORD_STATUS } from 'graphql/queries/recordStatus';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import { useEffect, useMemo } from 'react';
import { IFindProblemCodeByRecordType, IFindRecordStatusByRecordType, IProblemCode, IRecordStatus } from 'ui-component/RecordView/types';
import { GetTargetTenantsByRecordType, GetTargetTenantsByRecordTypeVariables, TargetTenant } from 'ui-component/records/types';
import { IGetUsers, IUser } from 'views/backoffice/users/types';

export interface useMyDropdownsProps {
    recordTypeId: number;
    dropdowns?: ('PROBLEM_CODE' | 'RECORD_STATUS' | 'USER_LIST' | 'TARGET_TENANTS')[];
    onCompleted?: (data: {
        problemCodeList?: IProblemCode[];
        recordStatusList: IRecordStatus[];
        usersList: IUser[];
        targetTenantList?: TargetTenant[];
    }) => void;
}

let localDropowns = ['PROBLEM_CODE', 'RECORD_STATUS', 'USER_LIST', 'TARGET_TENANTS'];

const useMyDropdowns = ({ recordTypeId, onCompleted, dropdowns }: useMyDropdownsProps) => {
    const [getUsers, { data: usersData, loading: loadingUsers }] = useLazyQuery<IGetUsers>(QUERY_GET_USERS);
    const [getProblemCode, { data: problemCodeData, loading: loadingProblemCode }] = useLazyQuery<IFindProblemCodeByRecordType>(
        QUERY_GET_PROBLEM_CODE,
        {
            variables: { data: { recordType: Number(recordTypeId) } },
            fetchPolicy: 'no-cache'
        }
    );
    const [getRecordStatus, { data: recordStatusData, loading: loadingRecordStatus }] = useLazyQuery<IFindRecordStatusByRecordType>(
        QUERY_FIND_RECORD_STATUS,
        {
            variables: { data: { recordTypes: [Number(recordTypeId)] } },
            fetchPolicy: 'no-cache'
        }
    );
    const [getTargetTenants, { loading: loadingTargetTenants, data: tenantTransmissionData }] = useLazyQuery<
        GetTargetTenantsByRecordType,
        GetTargetTenantsByRecordTypeVariables
    >(QUERY_FIND_TARGET_TENANTS, {
        variables: { data: { recordTypeId: Number(recordTypeId) } },
        fetchPolicy: 'no-cache'
    });

    const usersList = useMemo(
        () =>
            usersData?.getSaasUsers
                ? [...usersData?.getSaasUsers].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                : [],
        [usersData]
    );
    const problemCodeList = useMemo(
        () =>
            problemCodeData?.findProblemCodeByRecordType
                ? [...problemCodeData.findProblemCodeByRecordType].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                : [],
        [problemCodeData]
    );
    const recordStatusList = useMemo(
        () =>
            recordStatusData?.findRecordStatusByRecordType
                ? [...recordStatusData.findRecordStatusByRecordType].sort((a, b) =>
                      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                : [],
        [recordStatusData]
    );
    const targetTenantList = useMemo(
        () =>
            tenantTransmissionData?.getTargetTenantsByRecordType
                ? [...tenantTransmissionData.getTargetTenantsByRecordType].sort((a, b) =>
                      a.targetTenantToTransmit.name.toLowerCase().localeCompare(b.targetTenantToTransmit.name.toLowerCase())
                  )
                : [],
        [tenantTransmissionData]
    );

    const loadingLists = loadingProblemCode || loadingRecordStatus || loadingUsers || loadingTargetTenants;

    useEffect(() => {
        if (!loadingLists && onCompleted) {
            onCompleted?.({ recordStatusList, usersList, problemCodeList });
        }
    }, [loadingLists, onCompleted, problemCodeList, recordStatusList, usersList]);

    useEffect(() => {
        if (dropdowns) localDropowns = dropdowns;
    }, [dropdowns]);

    useEffect(() => {
        if (recordTypeId) {
            if (localDropowns.includes('PROBLEM_CODE')) getProblemCode();
            if (localDropowns.includes('RECORD_STATUS')) getRecordStatus();
            if (localDropowns.includes('USER_LIST')) getUsers();
            if (localDropowns.includes('TARGET_TENANTS')) getTargetTenants();
        }
    }, [getProblemCode, getRecordStatus, getUsers, getTargetTenants, recordTypeId]);

    return { loading: loadingLists, problemCodeList, recordStatusList, usersList, targetTenantList };
};

export default useMyDropdowns;
