import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PropsWithChildren } from 'react';

export const PanelFieldSectionInline = ({
    title,
    children,
    noPadding = false,
    isFocused = false,
    labelStyle = null
}: PropsWithChildren<{ title: string | JSX.Element; noPadding?: boolean; isFocused?: boolean; labelStyle?: any }>) => {
    const theme = useTheme();
    return (
        <Grid item xs={12} sx={{ py: noPadding ? 0 : '10px' }}>
            <Grid container alignItems="center">
                <Grid item xs={4}>
                    <Typography
                        style={
                            labelStyle ?? {
                                paddingTop: 18,
                                paddingLeft: 25,
                                fontWeight: 600,
                                fontSize: '13px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                color: isFocused ? theme.palette.secondary[800] : '#616161'
                            }
                        }
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={8} sx={{ textAlign: 'right', paddingRight: '10px' }}>
                    <Grid style={{ paddingTop: 18, paddingLeft: 10 }}>{children}</Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
