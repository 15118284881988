import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { QUERY_GET_USER_DETAILS } from 'graphql/mutations/login';
import { GetUserDetails, UserLoginDetail } from 'views/pages/authentication/SelectTenant/types';

export type useMyTenantsProps = {
    onCompleted?: (data: UserLoginDetail[]) => void;
    immediate?: boolean;
};

export const useMyTenants = (options?: useMyTenantsProps) => {
    const [getUserDetails, { loading, data: tenantsData }] = useMutation<GetUserDetails>(QUERY_GET_USER_DETAILS, {
        onCompleted(data) {
            options?.onCompleted?.(data.getUserDetails || []);
        }
    });

    const tenantList = tenantsData?.getUserDetails || [];

    useEffect(() => {
        if (options?.immediate) getUserDetails();
    }, [getUserDetails, options?.immediate]);

    return { getUserDetails, loading, tenantList };
};
