import { Chip } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

export const StatusEditComponent = ({ value, row }: GridRenderEditCellParams<Record<string, any>, boolean>) => {
    const apiRef = useGridApiContext();
    if (!row.id || typeof value === 'undefined') return null;

    const handleValueChange = (newVal: boolean) => async () => {
        if (newVal !== value)
            await apiRef.current.setEditCellValue({
                field: 'enabled',
                id: row.id,
                value: newVal
            });
    };

    if (value)
        return (
            <Chip
                label="Active"
                size="small"
                onClick={handleValueChange(false)}
                sx={(theme) => ({
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                    color: theme.palette.success.dark
                })}
            />
        );
    return (
        <Chip
            label="Inactive"
            size="small"
            onClick={handleValueChange(true)}
            sx={(theme) => ({
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                color: theme.palette.orange.dark
            })}
        />
    );
};
